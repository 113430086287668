import request from '@/utils/request'

// 获取当前登录用户区域
export function getLoginAreaId() {
  return request({
    url: '/authenication/getUserRolePermission',
    method: 'POST',
    data: {}
  })
}

// 获取当前登录用户区域详情
export function getLoginAreaInfo(data: any) {
  return request({
    url: '/areaConfig/getAreaCodeById',
    method: 'POST',
    data
  })
}

// 获取所有角色
export function getRoleMenus() {
  return request({
    url: '/admin/menu/getRoleMenu',
    // method: 'GET',
    method: 'POST'
  })
}

// 登录跳转获取token
export function jumpLogin(data: Object) {
  return request({
    url: '/authenication/autoLogin',
    method: 'POST',
    data: data
  })
}
// ------------------------------------登录认证的-------第一版的后的接口--------------------------
// 查询用户信息
export function getPersonInfo(data: Object) {
  return request({
    url: '/zctUser/getPersonInfos',
    method: 'POST',
    data,
  })
}

// 获取用户信息接口
export function getUserInfosApi(data: Object) {
  return request({
    url: '/user/getUserInfo',
    method: 'POST',
    data: data
  })
}

// 校验身份证是否合格
export function validIdentity(data: Object) {
  return request({
    url: '/commonValid/validIdentity',
    method: 'POST',
    data: data
  })
}

// 首次使用人脸识别
export function firstFaceVerify(data: Object) {
  return request({
    url: '/faceVerify/personnel/first',
    method: 'POST',
    data: data
  })
}

// 获取企业详情
export function getCompanyDetailApi(data: Object) {
  return request({
    baseURL: process.env.VUE_APP_JST_URL,
    url: '/sys/user/getCompanyDetail',
    method: 'POST',
    data: data
  })
}

// ------------------------------------登录认证的-------二次改版的后的接口--------------------------

// 获取所属行业下拉数据
export function getIndustryData(data: Object) {
  return request({
    url: '/dictionary/getByCategoryName',
    method: 'POST',
    data: data
  })
}

// 获取产权交易中心下拉数据
export function getPropertyrightTransactionCenterData(data: Object) {
  return request({
    url: '/dictionary/getByCategoryName',
    method: 'POST',
    data: data
  })
}

// 判断登录认证状态
export function getVerifyStatus(data: Object) {
  return request({
    url: '/authenication/valid',
    method: 'POST',
    data: data
  })
}
// 认证企业、政府------------表单数据-------------
// enterprise  government  organization
// 新增
export function addOrganizationData(data: Object) {
  return request({
    url: '/company/build',
    method: 'POST',
    data: data
  })
}
// 编辑
export function updateOrganizationData(data: Object) {
  return request({
    url: '/company/updateCompany',
    method: 'POST',
    data: data
  })
}
// 获取详情
export function getOrganizationDetail(data: Object) {
  return request({
    url: '/company/getPersonCompany',
    method: 'POST',
    data: data
  })
}
// 获取详情2
export function getOrganizationDetail2(data: Object) {
  return request({
    url: '/person/bank/getPersonOran',
    method: 'POST',
    data: data
  })
}

// ----------------------------------独立的登录注册的主页---接口--------------------------
// 登录
export function singleLogin(data: Object) {
  return request({
    url: '/rural/login/login',
    method: 'POST',
    data: data
  })
}

// 注册
export function singleRegiter(data: Object) {
  return request({
    url: '/rural/login/regist',
    method: 'POST',
    data: data
  })
}

// 图形验证码
export function singleImgVerity(data: Object) {
  return request({
    url: `/rural/login/getValidCode`,
    method: 'POST',
    data: data
  })
}

// 短信验证码
export function singleSMSverity(data: Object) {
  return request({
    url: `/rural/login/sendMessage`,
    method: 'POST',
    data: data
  })
}

// ----------忘记密码系列--------------------------------------


// 发送忘记密码的手机验证码
export function forgetMessage(data: Object) {
  return request({
    url: `/rural/login/send/forget/message`,
    method: 'POST',
    data: data
  })
}
// 下一步 验证忘记密码的手机
export function validPhone(data: Object) {
  return request({
    url: `/rural/login/valid/forget/password`,
    method: 'POST',
    data: data
  })
}
// 重置密码
export function resetforgetpwd(data: Object) {
  return request({
    url: `/rural/login/change/forget/password`,
    method: 'POST',
    data: data
  })
}

// ----------个人用户校验接口 --------------------------------------

// 个人用户认证校验
export function validPersonnelUser() {
  return request({
    url: `/admin/person/validPersonnelUser`,
    method: 'POST'
  })
}

// 修改为个人用户
export function reviseForPerson() {
  return request({
    url: `/admin/person/changePersonnelUser`,
    method: 'POST'
  })
}
