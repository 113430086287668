import { localStore, sessionStore } from '@/utils/data-management'
import { AxiosResponseForZCT, ZCTAPI } from '@/utils/types'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Message, MessageBox } from 'element-ui'
// import jsonbigStringParse from '@/utils/jsonbigStringParse'

// import Vue from 'vue'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI)
// 验签---------------
import md5 from 'js-md5'
import StringUtils from '@/utils/strRandom'
import * as secret from '@/utils/secret'
// AxiosError 只有 message， 接口返回 msg， 故此扩展一下
interface customError extends AxiosError {
  msg: string
}

const service: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000,
  withCredentials: true // send cookies when cross-domain requests
})
// Request interceptors
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (['/villageManage/export', '/back/listingApply/exportZip', '/farmersInfo/exportFarmersInfoTemplate', '/villageManage/import', "/file/upload", "/mortgageApproval/exportById", "/loanApproval/exportById", "/creditAssessorRel/userRoleBeAssessorList", "/creditAssessorRel/editAssessorAndBeAssessor", "/creditAssessorRel/addAssessorAndBeAssessor"].indexOf(config.url ? config.url : 'null') > -1) {
      config.timeout = 0
    }
    const zctToken = localStore.get('zctToken')
    const timestamp = new Date().getTime() + ''
    const nonce = StringUtils.randomStr(32)
    const ZCT_SECRET = '21fa6sd1f95w1133edsafas6'
    const encrptString = `${timestamp}.${ZCT_SECRET}.${nonce}`
    const sign = md5(encrptString)
    config.headers = {
      ...config.headers, //@其他的
      sign,
      timestamp,
      nonce,
      zctToken: zctToken
    }
    const result = config.url && (config.url.indexOf("/back/listingApply/exportZip") > -1 || config.url.indexOf("/file/upload") > -1 || config.url.indexOf('/villageManage/import') > -1 || config.url.indexOf('/some/import') > -1 || config.url.indexOf('/subsidy/template/upload') > -1)
    if (!result && config.method && config.method.toLocaleUpperCase() == 'POST') {
      if (process.env.VUE_APP_SYSTEM_TYPE) {
        !config.data && (config.data = {})
        config.data.systemType = process.env.VUE_APP_SYSTEM_TYPE == 'zct' ? 1 : 0
      }
      // console.log('request.data', config.data)
      config.data = secret.default.encrypt(JSON.stringify(config.data))
      config.headers['Content-Type'] = "application/json"
    } else {
      // console.log('request.data', config.data)
    }
    return config
  },
  (error: AxiosError) => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response: AxiosResponse<ZCTAPI.Res>) => {
    /**
     * TODO: 补充服务端状态码规范
     */
    // if (response.data && Object.prototype.toString.call(response.data) === '[object String]') {
    //   response.data = jsonbigStringParse(secret.default.decrypt(response.data as any))
    // }
    // console.log('response.data', response.data)
    if (response.data.code == 1000 || response.data.code === 0) {
      return response
    } else if (response.data.code == 3000) {
      // Message.error({
      //   message: response.data.message || "登录超时，请重新登录",
      //   duration: 3 * 1000,
      // })
      MessageBox.confirm(response.data.message || "登录超时，请返回重新登录", "提示", {
        distinguishCancelAndClose: false,
        type: 'warning',
        showClose: false,
        confirmButtonText: '重新登录',
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        center: true,
        lockScroll: true
      })
        .then(() => {
          // if (sessionStore.get('bID') && (sessionStore.get('bID') === 'LOGIN001')) {
          window.location.href = process.env.VUE_APP_ENVIRONMENTAL_SINGLELOGIN ? process.env.VUE_APP_ENVIRONMENTAL_SINGLELOGIN : window.location.href
          // } else {
          //   window.location.href = process.env.VUE_APP_ENVIRONMENTAL_PATH ? process.env.VUE_APP_ENVIRONMENTAL_PATH : window.location.href
          // }
          localStore.remove('zctToken')
          localStore.remove('ticket')
          localStore.remove('routes')
          localStore.remove('isAuth')
          localStore.remove('types')
          localStore.remove('ISPASSSTATUS')

        })
        .catch((err) => {
          console.log(err)
        })

      return Promise.reject(response)
    } else if (response.data.code == 1011 && response.config.url == '/admin/person/validPersonnelUser') {
      return response
    } else if (response.config.responseType === "blob") {
      return response
    } else {
      if (response.config.url && ['/faceVerify/personnel/first', '/common/verify/QRCodeInfo'].indexOf(response.config.url as any) > -1 && response.data.message == 'http请求异常') {
        Message.error('身份证识别失败，请重新上传')
      } else {
        Message.error(response.data.message || '系统未知异常，请联系管理员')
      }
      return Promise.reject(response)
    }
  },
  (error: customError) => {
    const { data } = error.response as any
    const res = data as AxiosResponseForZCT
    Message({
      message: error.msg || res.message || '系统未知异常，请联系管理员',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
export function request<T>(params: object): Promise<T> {
  return service(params) as any
}
