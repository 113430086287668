import { Dictionary } from './types'
import { getSearch } from '@/utils/getSearch'

export function getValueFromUrlQuery<K extends string = string>(
  keys?: Array<K>
): { [V in K]: string } | Dictionary<string> {
  // const queryObj = new URLSearchParams("?ticket=braUM3kLMJCs2nc40ht9#/home")
  const queryObj = new URLSearchParams(getSearch())
  if (keys && keys.length > 0) {
    return keys.reduce(
      (res, key) => Object.assign(res, { [key]: queryObj.get(key) || '' }),
      {} as { [V in K]: V }
    )
  } else {
    const result: Dictionary<string> = {}
    queryObj.forEach((v, k) => {
      result[k] = v
    })
    return result
  }

}
