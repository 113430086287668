var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c("SidebarLogo"),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.themeStyle["--menu-bg"],
                "text-color": _vm.themeStyle["--menu-text"],
                "active-text-color": _vm.themeStyle["--menu-active-text"],
                "unique-opened": false,
                "collapse-transition": false,
                mode: "vertical"
              }
            },
            _vm._l(_vm.routes, function(route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: {
                  item: route,
                  "base-path": route.path,
                  "is-collapse": _vm.isCollapse,
                  isFirstLevel: true
                }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }