import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import routes from './routes'
Vue.use(VueRouter)
type RouterParser<T> = (r: T[]) => T[]
const routeParser: RouterParser<RouteConfig> = (routes) => {
  const joinPath = (prefix: string, current: string) => {

    if (prefix.endsWith('/')) {
      if (current.startsWith('/')) {
        return `${prefix}${current.split('/')[1]}`
      } else {
        return `${prefix}${current}`
      }
    } else {
      if (current.startsWith('/')) {
        return `${prefix}${current}`
      } else {
        return `${prefix}/${current}`
      }
    }
  }
  const recursTransfer = (route: RouteConfig, prefixPath: string, parentRoute?: RouteConfig) => {
    // 递归获取下一层抛出的所有独立路由并处理
    if (route.children) {
      const currentPath: string = joinPath(prefixPath, route.path)
      const standaloneRoutes = route.children
        .map((childRoute: RouteConfig) => recursTransfer(childRoute, currentPath, route))
        .filter((r: RouteConfig | undefined) => r !== undefined)
      if (parentRoute) {
        // 拷贝后将 standalone 中配置的信息移动到 meta, 并插入到父级路由中
        // 完成后 standalone 属性将作为删除标记
        const routesCopy: RouteConfig[] = standaloneRoutes.map((r: any) => Object.assign({}, r))
        routesCopy.forEach((r: RouteConfig) => {
          // 处理路由对象
          r.path = joinPath(currentPath, r.path)
          r.meta.hidden = true
          r.meta.breadcrumb = r.meta.standalone.breadcrumb
          r.meta.activeMenu = r.meta.standalone.activeMenu
        })
        if (parentRoute.children) {
          parentRoute.children.push(...routesCopy)
        } else {
          parentRoute.children = routesCopy
        }
      }
    }
    // 如果当前路由为独立路由, 则将当前路由抛给上层调用
    if (route.meta && route.meta.standalone) {
      return route
    } else {
      return undefined
    }
  }
  const recursDel = (route: RouteConfig) => {
    // 递归移除所有标记为 standalone 的路由
    if (route.children) {
      route.children.forEach((rc: RouteConfig) => {
        if (!rc.meta.standalone && rc.children) {
          rc.children = rc.children.filter((rcc: RouteConfig) => !(rcc.meta && rcc.meta.standalone))
          recursDel(rc)
        }
      })

    } else {
      return
    }
  }
  routes.forEach((route: RouteConfig) => {
    recursTransfer(route, '')
  })
  routes.forEach((route: RouteConfig) => {
    recursDel(route)
  })
  let allPath: any = null, forRoutes: any = (routes as any)[3].children
  for (let i = 0; i < forRoutes.length; i++) {
    if (forRoutes[i].path == '*') {
      allPath = forRoutes[i]
      forRoutes.splice(i, 1)
    }
  }
  allPath && forRoutes.push(allPath)
  return routes
}
const resultRoutes = routeParser(routes)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location: any) {
  return (routerPush.call(this, location) as any).catch((error: any) => error)
}
const router = new VueRouter({
  routes: resultRoutes
})
// router.beforeEach((to, from, next) => {
//   // TODO: 当 token 过期则返回登录页面
//   next()
// })

export default router
