

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


import '@/assets/icons/components'
import '@/assets/style/global.css'
import '@/assets/style/index.scss'
import 'normalize.css'
import nzhcn from 'nzh/dist/nzh.cn.min.js'
import Vue from 'vue'
import SvgIcon from 'vue-svgicon'
import App from './App.vue'
import './router/guards'
import router from './router/router'
import store from './store'
// 富文本
import 'quill/dist/quill.snow.css'
import timeFormat from '@/utils/timeFormat';
import { toThousandsSigns, numMoneyToChinese } from '@/utils/moneynNumberFilter';
// 富文本 end

import Component from 'vue-class-component'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

Vue.filter("dateFormat", timeFormat);

Vue.filter('toThousandsSigns', toThousandsSigns) // 数值加千分位逗号
Vue.filter('priceToCn', nzhcn.encodeB) // 数字转中文大写(不带整余判断)
Vue.filter('numMoneyToChinese', numMoneyToChinese) // 数字转中文大写(元)(带整余判断)

Vue.config.productionTip = false;

Vue.use(ElementUI)
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
