export function getSearch() {
    let returnVal: string = '',
        dot: string = '?ticket='
    if (window.location.href.indexOf(dot) > -1) {
        let url: any = window.location.href.split(dot),
            ticket: any = url[url.length - 1]
        if (ticket.indexOf('#') > -1) {
            ticket = ticket.split('#')[0]
        }
        returnVal = dot + ticket
    }
    return returnVal
}