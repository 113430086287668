import { Dictionary } from './types'

enum ENV {
  DEV,
  PROD
}

const env: ENV = document.domain === 'localhost' ? ENV.DEV : ENV.PROD

export enum System {
  LOGIN = '登录',
  JST = '捷算通',
  ZQT = '智企通',
  ZCT = '企业信息披露服务平台'
}

/**
 * 该枚举变量记录各系统的开发环境_相对_地址, 用于从登录页面跳转
 * tip: 枚举变量中无法使用 process.env.VUE_APP* 常量, 因此使用相对地址.
 */
enum DevSystemUrl {
  LOGIN = '/',
  JST = '/jst.html',
  ZQT = '/zqt.html'
}

/**
 * 该枚举变量记录各系统的线上_完整_地址, 用于从登录页面跳转
 * TODO: 待补充
 */
const ProdSystemUrl: Dictionary<string> = {}

type SystemUrl = string
type SystemUrlMap = Map<string, SystemUrl>
const getSystemUrlMap: () => SystemUrlMap = () => {
  return env === ENV.DEV
    ? new Map(
      Object.entries({
        [System.LOGIN]: `http://localhost:${process.env.VUE_APP_PORT}${DevSystemUrl.LOGIN}`,
        [System.JST]: `http://localhost:${process.env.VUE_APP_PORT}${DevSystemUrl.JST}`,
        [System.ZQT]: `http://localhost:${process.env.VUE_APP_PORT}${DevSystemUrl.ZQT}`,
        [System.ZCT]: ProdSystemUrl.ZCT
      })
    )
    : new Map(
      Object.entries({
        [System.LOGIN]: ProdSystemUrl.LOGIN,
        [System.JST]: ProdSystemUrl.JST,
        [System.ZQT]: ProdSystemUrl.ZQT,
        [System.ZCT]: ProdSystemUrl.ZCT
      })
    )
}

export function setProdSystemUrl(sys: System, url: string): void {
  // 写死捷算通的相对地址与后端部署地址一致, 在收集线上地址时初始化
  // 临时方案, 待重构
  const urlObj = new URL(window.location.href)
  ProdSystemUrl.LOGIN = urlObj.origin
  ProdSystemUrl.JST = urlObj.origin + '/sys-jst'
  switch (sys) {
    case System.ZQT:
      ProdSystemUrl.ZQT = url
      break
    case System.ZCT:
      ProdSystemUrl.ZCT = url
      break
  }
}

export function getTargetSystemUrl(sys: System): string {
  return getSystemUrlMap().get(sys) as SystemUrl
}

export function enterSystem(options: {
  sys: System
  url?: string
  userId?: string
  key_token?: string
  enterpriseId?: string
  hash?: string
  newWindow?: boolean
}): void {
  const { sys, url, userId, key_token, enterpriseId, hash, newWindow } = options
  if (sys === System.LOGIN) {
    window.location.href = getTargetSystemUrl(sys) // 临时方案, 待重构
    return
  } else if (sys === System.JST) {
    if (!key_token) {
      throw new Error('Error: key-token is empty')
    }
    if (!userId) {
      throw new Error('Error: userId is empty')
    }
    const urlObj: URL = new URL(getTargetSystemUrl(sys))
    urlObj.searchParams.set('key_token', key_token)
    urlObj.searchParams.set('userId', userId)
    if (hash) {
      urlObj.hash = hash
    }
    if (newWindow) {
      window.open(urlObj.toString(), '_blank')
    } else {
      window.location.href = urlObj.toString()
    }
  } else {
    if (!url) {
      throw new Error('Error: url is empty')
    } else if (!userId) {
      throw new Error('Error: userId is empty')
    }
    const originUrlObj: URL = new URL(url)
    const msg: string = originUrlObj.searchParams.get('msg') || ''
    const newUrlObj: URL = new URL(getTargetSystemUrl(sys)) // 含有一次性 key
    newUrlObj.searchParams.set('msg', msg)
    newUrlObj.searchParams.set('userId', userId)
    if (enterpriseId) {
      newUrlObj.searchParams.set('enterpriseId', enterpriseId)
    }
    window.location.href = newUrlObj.toString()
  }
}
