
import path from 'path'
import { Component, Prop, Vue, InjectReactive } from 'vue-property-decorator'
import { Route, RouteConfig } from 'vue-router'
import { isExternal } from '@/utils/validate'
import SidebarItemLink from './SidebarItemLink.vue'
import { UserModule } from '@/store/user.store'
// import { Theme } from '@/assets/style/themes/index.scss'
import password from '@/assets/images/icon_password.png'

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: 'SidebarItem',
  components: {
    SidebarItemLink
  }
})
export default class extends Vue {
  @Prop({ required: true }) private item!: RouteConfig
  @Prop({ default: false }) private isCollapse!: boolean
  @Prop({ default: true }) private isFirstLevel!: boolean
  @Prop({ default: '' }) private basePath!: string
  // @InjectReactive() themeStyle!: Theme

  get password(): string {
    return password
  }

  get alwaysShowRootMenu() {
    return this.item.meta && this.item.meta.alwaysShow
  }

  get showingChildNumber() {
    // 如果该路由下有子路由, 则返回子路由中不具有 meta.hidden 的路由数量, 否则返回 0
    return this.item.children
      ? this.item.children.filter((item) => !(item.meta && item.meta.hidden)).length
      : 0
  }

  get theOnlyOneChild() {
    if (this.showingChildNumber > 1) {
      return null
    }
    if (this.item.children) {
      for (let child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return { ...this.item, path: '' }
  }

  private resolvePath(routePath: string) {
    if (isExternal(routePath)) {
      return routePath
    }
    if (isExternal(this.basePath)) {
      return this.basePath
    }
    // 登录的时候 vuex 记录登录的系统 寻找对应的 路由
    // return path.resolve(UserModule.loginSystem, this.basePath, routePath)
    return path.resolve(this.basePath, routePath)
  }
}
