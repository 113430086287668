var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "logo",
      on: {
        click: function($event) {
          return _vm.$router.push("/home")
        }
      }
    },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "light", content: "首页", placement: "right-end" }
        },
        [
          _c(
            "div",
            { staticClass: "logo-content" },
            [
              _c("el-image", {
                staticClass: "no-move-img",
                attrs: { width: "24px", src: _vm.ruralLogo }
              }),
              _vm.systemType == "rural"
                ? _c("span", [_vm._v("农村信息综合管理系统")])
                : _c("span", [_vm._v("企业信息披露服务管理后台")])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }