var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "bread-crumb" },
    [
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _c("div", { staticClass: "title" }, [
        _vm._v(
          _vm._s(_vm.$route.meta.title != "首页" ? _vm.$route.meta.title : "")
        )
      ]),
      _vm.$route.meta.subtitle
        ? _c("div", { staticClass: "subtitle" }, [
            _vm._v(_vm._s(_vm.$route.meta.subtitle))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }