
import { Component, Vue, ProvideReactive } from 'vue-property-decorator'
import Sidebar from './Sidebar/index.vue'
import BreadCrumb from './BreadCrumb/index.vue'
import Navbar from './Navbar/index.vue'
import AppMain from './AppMain/index.vue'
import { SettingsModule } from '@/store/setting.store'
import theme from '@/theme'
import { System } from '../utils/systems'

@Component({
  name: 'Layout',
  components: {
    Sidebar,
    Navbar,
    BreadCrumb,
    AppMain
  }
})
export default class extends Vue {
  private theme = theme
  // @ProvideReactive()
  private get themeStyle() {
    switch (this.$store.state.loginSystem) {
      case System.JST:
        return this.theme.dark
      case System.ZQT:
        return this.theme.light
      default:
        return this.theme.light
    }
  }
  private get isCollapse() {
    return SettingsModule.hideSidebar
  }
}
