
import { Component, Vue } from 'vue-property-decorator'
import Hamburger from '@/components/Hamburger/index.vue'
import { SettingsModule } from '@/store/setting.store'
import { USER, UserType } from '../../utils/types'
import { localStore, sessionStore } from '@/utils/data-management'
import questionSrc from '@/assets/images/question.png'

enum Command {
  Logout = 'logout',
  AccountManage = 'accountManage'
}

@Component({
  name: 'Navbar',
  components: {
    Hamburger
  }
})
export default class NavBar extends Vue {
  private questionSrc: any = questionSrc
  private get userName(): string {
    return this.$store.state.userName || sessionStore.get('UsIF')
  }
  private get userAccount(): string {
    return this.$store.state.userInfo.name || this.$store.state.userInfo.account || 'Unknown'
  }
  private get isAuth() {
    return this.$store.state.userInfo.isAuth || localStore.get('isAuth')
  }
  private get userType(): UserType {
    return this.$store.state.userInfo.type
  }
  private toEnterpriseInfo(): void {
    this.$store.dispatch('toEnterpriseInfo')
  }
  private toggleSidebar() {
    SettingsModule.toggleSidebarAction()
  }
  private refresh() {
    window.location.reload()
  }
  private help() {
    this.$message.info('暂未开放，敬请期待')
  }
  private handleDropdownCommand(cmd: Command) {
    switch (cmd) {
      case Command.Logout:
        this.$store.dispatch('logout')
        break
      case Command.AccountManage:
        this.$store.dispatch('toAccountManage')
    }
  }
}
