const SymbolTable = [
  '~',
  '`',
  '!',
  '',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '-',
  '_',
  '+',
  '=',
  '{',
  '}',
  '[',
  ']',
  '\\',
  '|',
  ':',
  ';',
  '“',
  '"',
  "'",
  '‘',
  '<',
  ',',
  '.',
  '>',
  '?',
  '/',
  '、'
]

export const isValidUsername = (str: string) =>
  ['admin', 'editor'].indexOf(str.trim()) >= 0

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path)

export const isArray = (arg: any) => {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

export const hasNumber = (val: string) => {
  return /[0-9]/.test(val)
}

export const hasWord = (val: string) => {
  return /[A-Za-z]/.test(val)
}

export const hasSpace = (val: string) => {
  return /(^\s+)|(\s+$)|\s+/g.test(val)
}

// 身份证
export const hasIdCard = (val: string) => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return reg.test(val)
}

// 统一社会信用代码
export const hasSocialCreditCode = (val: string) => {
  const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
  return reg.test(val)
}

export const hasSymbol = (val: string) => {
  for (let v of val) {
    if (SymbolTable.includes(v)) {
      return true
    }
  }
  return false
}

export const isValidURL = (url: string) => {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

export const num2Fixed=(num)=>{
	var reg = /^[1-9]+\d*(\.\d{0,2})?$|^0?\.\d{0,2}$/;
   return reg.test(num)
}
