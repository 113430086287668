
import { Component, Vue, Watch } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb/index.vue'

@Component({
  name: 'BreadCrumb',
  components: {
    Breadcrumb
  }
})
export default class extends Vue {
  // @Watch('$route.meta.title', { deep: true })
  // private hanleRoute(newVal: string) {
  //   this.$route.meta.title = newVal
  // }

  get titles() {
    console.log('------------------------------', this.$route.meta.title)
    return this.$route.meta.title
  }
  get subtitle() {
    return this.$route.meta.subtitle
  }
  created() {
    console.log('----当前路由位置---》', this.$route.meta.title)
  }
}
