import CryptoJS from 'crypto-js'

let key = 'wqslxp12nf60mzp3',
  iv = '567jiopmnbgtredc',
  // 生产环境
  product = 'production',
  // 扶绥环境
  fusui = 'fusui'
switch (process.env.VUE_APP_TITLE) {
  case product:
    key = 'Wtrssxa26fKfxp21'
    iv = '04YhmwXcgYuoPf6D'
    break
  case fusui:
    key = 'Iyx712xYoPm90pxy'
    iv = '1xCg5bxcq9i8mxz8'
    break
  default:
    break
}

// 加密
function encrypt(word: string) {
  const keydata = CryptoJS.enc.Utf8.parse(key);
  const ivdata = CryptoJS.enc.Utf8.parse(iv);

  let srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, keydata, {
    iv: ivdata,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });

  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

// 解密
function decrypt(data: string, fnKey: string = '', fnIv: string = '') { //key,iv：16位的字符串
  let keydata = CryptoJS.enc.Latin1.parse(fnKey ? fnKey : key);
  let ivdata = CryptoJS.enc.Latin1.parse(fnIv ? fnIv : iv);
  let decrypted = CryptoJS.AES.decrypt(data, keydata, {
    iv: ivdata,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export default {
  encrypt,
  decrypt
}