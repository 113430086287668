import { UserModule } from '@/store/user.store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Route } from 'vue-router'
import router from './router'


router.beforeEach((to: Route, from: Route, next: any) => {
  NProgress.start()
  // UserModule.GenerateRoutes()  // 清空this.$route

  // if (!localStore.get("zctToken")) {
  // } else {
    
  next()
  // }
})

router.afterEach((to: Route) => {
  NProgress.done()
  // document.title = to.meta.title
})

