
import * as pathToRegexp from 'path-to-regexp/dist/index'
import { Component, Vue, Watch } from 'vue-property-decorator'
import VueRouter, { RouteRecord, Route, RouteConfig } from 'vue-router'

let flattenRoutes: RouteConfig[]

@Component({
  name: 'Breadcrumb'
})
export default class extends Vue {
  private breadcrumbs: RouteRecord[] = []

  @Watch('$route', { deep: true })
  private onRouteChange(route: Route) {
    // if you go to the redirect page, do not update the breadcrumbs
    if (route.path.startsWith('/redirect/')) {
      return
    }
    this.getBreadcrumb()
  }

  created() {
    this.initFlattenRouteTable()
    this.getBreadcrumb()
  }

  private initFlattenRouteTable(): void {
    const routesToAppend: RouteConfig[] = []
    const flatRoutes = (routes: RouteConfig[]) => {
      routes.forEach((route: RouteConfig) => {
        if (route.children) {
          flatRoutes(route.children)
          routesToAppend.push(...route.children)
        } else {
          routesToAppend.push(route)
        }
      })
    }
    flatRoutes((this.$router as any).options.routes)
    flattenRoutes = routesToAppend
  }

  private getBreadcrumb() {
    if (this.$route.meta.breadcrumb) {
      this.breadcrumbs = this.$route.meta.breadcrumb.map((name: string) =>
        flattenRoutes.find((route: RouteConfig) => route.name === name)
      )
      return
    }
    let matched = this.$route.matched.filter((item) => item.meta && item.meta.title)
    const first = matched[0]
    // if (!this.isDashboard(first)) {
    //   matched = [
    //     { path: '/dashboard', meta: { title: 'dashboard' } } as RouteRecord
    //   ].concat(matched)
    // }
    this.breadcrumbs = matched.filter((item) => {
      return item.meta && item.meta.title && item.meta.breadcrumb !== false
    })
  }

  // private isDashboard(route: RouteRecord) {
  //   const name = route && route.name
  //   if (!name) {
  //     return false
  //   }
  //   return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
  // }

  private pathCompile(path: string) {
    // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
    const { params } = this.$route
    const toPath = pathToRegexp.compile(path)
    return toPath(params)
  }

  private handleLink(item: RouteConfig) {
    this.$router.push({ name: item.name })
  }
}
