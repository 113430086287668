var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.item.meta || !_vm.item.meta.hidden
    ? _c(
        "div",
        {
          class: [
            "menu-wrapper",
            _vm.isCollapse ? "simple-mode" : "full-mode",
            { "first-level": _vm.isFirstLevel }
          ]
        },
        [
          !_vm.item.children || _vm.item.children.length == 0
            ? [
                _c(
                  "sidebar-item-link",
                  { attrs: { to: _vm.resolvePath(_vm.theOnlyOneChild.path) } },
                  [
                    _c(
                      "el-menu-item",
                      {
                        class: { "submenu-title-noDropdown": _vm.isFirstLevel },
                        attrs: { index: _vm.item.name }
                      },
                      [
                        _vm.theOnlyOneChild.meta.icon &&
                        _vm.theOnlyOneChild.meta.icon != "star"
                          ? _c("i", {
                              class: _vm.theOnlyOneChild.meta.icon,
                              attrs: { name: _vm.theOnlyOneChild.meta.icon }
                            })
                          : _vm.isFirstLevel
                          ? _c("span", {
                              staticClass: "spanSty",
                              attrs: { slot: "title" },
                              slot: "title"
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            class: {
                              spanStys:
                                !_vm.theOnlyOneChild.meta.icon ||
                                _vm.theOnlyOneChild.meta.icon == "star"
                            },
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(_vm._s(_vm.theOnlyOneChild.meta.title))]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            : _c(
                "el-submenu",
                {
                  attrs: { index: _vm.item.name, "popper-append-to-body": "" }
                },
                [
                  _c("template", { slot: "title" }, [
                    _vm.item.meta && _vm.item.meta.icon
                      ? _c("i", {
                          class: _vm.item.meta.icon,
                          attrs: { name: _vm.item.meta.icon }
                        })
                      : _vm._e(),
                    _vm.item.meta && _vm.item.meta.title
                      ? _c(
                          "span",
                          {
                            staticClass: "spanStyOne",
                            attrs: { slot: "title" },
                            slot: "title"
                          },
                          [_vm._v(_vm._s(_vm.item.meta.title))]
                        )
                      : _vm._e()
                  ]),
                  _vm.item.children
                    ? _vm._l(_vm.item.children, function(child) {
                        return _c("sidebar-item", {
                          key: child.path,
                          staticClass: "nest-menu",
                          attrs: {
                            item: child,
                            "is-collapse": _vm.isCollapse,
                            "is-first-level": false,
                            "base-path": _vm.resolvePath(child.path)
                          }
                        })
                      })
                    : _vm._e()
                ],
                2
              )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }