import { getToken, getUserAccountInfo, } from '@/api/api'
import { getCompanyDetailApi, getUserInfosApi, getRoleMenus, jumpLogin, } from '@/api/login'
import { logout as logout_api } from '@/api/user.api'
import { localStore, sessionStore } from '@/utils/data-management'
import { enterSystem, setProdSystemUrl, System } from '@/utils/systems'
import { USER, UserType } from '@/utils/types'
import { getValueFromUrlQuery } from '@/utils/url'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface UserInfo {
  name: string
  userId: string | number
  isAuth: USER.IsAuthed
  type: UserType
  enterpriseId: string | number
  key_token: string
  account: string
  avatar: string
  token: string
}

interface ZQTRootState {
  userInfos: any
  companyInfo: any
  userInfo: UserInfo
  loadingUserInfo: boolean
  loginSystem: System
}

function getUserInfo(): { userId: string; enterpriseId: string } {
  const { userId, enterpriseId } = getValueFromUrlQuery(['userId', 'enterpriseId'])
  if (userId && enterpriseId) {
    localStore.set('userId', userId)
    localStore.set('eid', enterpriseId)
    return { userId, enterpriseId }
  } else {
    const localUserId = localStore.get('userId')
    const localEnterpriseId = localStore.get('eid')
    if (!localUserId || !localEnterpriseId) {
      setProdSystemUrl(System.LOGIN, '')
      enterSystem({ sys: System.LOGIN })
      return { userId: '', enterpriseId: '' }
    } else {
      return { userId: localUserId, enterpriseId: localEnterpriseId }
    }
  }
}

export default new Vuex.Store<any>({
  strict: true,
  state: {
    userInfos: localStorage.userInfos ? JSON.parse(localStorage.userInfos) : {},
    companyInfo: localStorage.companyInfo ? JSON.parse(localStorage.companyInfo) : {},
    userInfo: {
      name: '',
      userId: '',
      isAuth: '',
      type: UserType.Personal,
      enterpriseId: '',
      key_token: '',
      account: '',
      avatar: ''
    },
    userName: '',
    loadingUserInfo: false,
    loginSystem: System.ZCT,
    jstUserInfo: {
      userId: '',
      accountType: ''
    },
    bankData: {},
    isLogin: false,
    hasToken: "",
    // 用户路由数据
    routeArr: "",
    routeDatass: []
  },
  mutations: {
    CLEAR_USERINFO(state) {
      state.userInfo['key_token'] = ''
      state.userInfo['userId'] = ''
      state.userInfo['enterpriseId'] = ''
    },
    MODIF_USERINFO(state, isAuth) {
      state.userInfo['isAuth'] = isAuth
      localStore.set("isAuth", isAuth)
    },
    CHANGE_ISLOGIN(state, tokken) {
      state.hasToken = tokken
    },
    CHANGE_ROUTEARR(state, route) {
      state.routeArr = route
    },
    ROUTE_UPDATE(state, data) {
      state.routeDatass = data
    },
    CHANGE_USERNAME(state, data) {
      state.userName = data
    },
    STORAGE_BANKDATA(state, data) {
      state.bankData = data
    }
  },
  actions: {
    // -------------------------------------登录方法------------------------------------------------------------
    // hanldeLogin({ state }) {
    //   let data = getValueFromUrlQuery(['ticket'])
    //   let dataString = data.ticket.substring(0, data.ticket.indexOf('#'))
    //   let datas: any = {
    //     ticket: dataString,
    //     client: 'admin'
    //   }
    //   jumpLogin(datas)
    //     .then((res) => {
    //       if (res.data.data) {
    //         localStore.set('tickets', dataString)
    //         localStore.set('zctToken', res.data.data)
    //         state.isLogin = true
    //       }
    //     })
    // },


    // -------------------------------------登录方法------------------------------------------------------------

    // 获取路由的方法
    hanldeRoute({ state }) {
      getRoleMenus().then((res) => {
        console.log(res.data)
        // let datas: any = res.data
        // state.routeArr = datas.data
      }).catch((res) => {
      }).finally(() => { })
    },
    // ---------------------------------------------
    getUserInfos({ state }) {
      state.loadingUserInfo = true
      // if (state.userInfos.token !== undefined) {
      //   return
      // }
      const url = new URL(window.location.href)
      var msg = url.searchParams.get('msg') || ''
      msg = 'BED197C9086BA399D80AE11E1178B06CC6FC0EE93966EAEFE91CE352F4E476168E391AB43D6E3D0B'
      getUserInfosApi({
        msg: "E92A5E20879AB66DAB99CCBCB1088D270DA0A189A0157075D9F42BF972CDB59C4F29DBD84C32B9EB"
      })
        .then((res: any) => {
          if (res.data.code === 1000) {
            state.userInfos = {
              ...JSON.parse(res.data.data.data),
              token: res.data.data['key-token']
            }

            localStore.set('key_token', res.data.data['key-token'])

            localStore.set('userInfos', state.userInfos)
            // 获取企业详情
            getCompanyDetailApi({
              userId: state.userInfos.userId
            }).then((result: any) => {
              if (result.data.msg === '操作成功') {
                state.companyInfo = { ...result.data.data }
                localStore.set('companyInfo', result.data.data)
              }
              state.loadingUserInfo = false
            })
          }
        })
        .finally(() => { })
    },
    toAccountManage({ state }): void {
      setProdSystemUrl(System.JST, '')
      const { key_token, userId } = state.userInfo
      let useridStr
      if (typeof userId === 'number') {
        useridStr = userId.toString()
      } else {
        useridStr = userId
      }
      enterSystem({
        sys: System.JST,
        key_token,
        userId: useridStr,
        hash: '#/user/account-management',
        newWindow: true
      })
    },
    toEnterpriseInfo({ state }): void {
      setProdSystemUrl(System.JST, '')
      const { key_token, userId } = state.userInfo
      let useridStr
      if (typeof userId === 'number') {
        useridStr = userId.toString()
      } else {
        useridStr = userId
      }
      enterSystem({
        sys: System.JST,
        key_token,
        userId: useridStr,
        hash: '#/user/info',
        newWindow: true
      })
    },
    logout() {
      // logout_api().then(() => {


      //   // setProdSystemUrl(System.LOGIN, '')
      //   // enterSystem({ sys: System.LOGIN })
      //   // window.location.href = `${process.env.VUE_APP_PROXY_JST}/login`

      // })
      if (process.env.VUE_APP_DOUBLE_LOGIN == 'F' || (sessionStore.get('bID') && (sessionStore.get('bID') === 'LOGIN001'))) {
        window.location.href = process.env.VUE_APP_ENVIRONMENTAL_SINGLELOGIN ? process.env.VUE_APP_ENVIRONMENTAL_SINGLELOGIN : window.location.href
      } else {
        window.location.href = process.env.VUE_APP_ENVIRONMENTAL_PATH ? process.env.VUE_APP_ENVIRONMENTAL_PATH : window.location.href
      }
      localStore.remove('zctToken')
      localStore.remove('ticket')
      localStore.remove('routes')
      localStore.remove('isAuth')
      localStore.remove('types')
      localStore.remove('ISPASSSTATUS')

    },
    async getUserInfo({ state }): Promise<void> {
      /**
       * 现在获取用户信息的方式存在问题:
       * 如果 localstorage 优先级较高, 则用户在没有正常退出并重新登录时有可能获取不到想要的用户和企业数据
       * 如果 url 优先级较高, 则无法判别用户是恶意修改 url 还是正常登录, 并且页面刷新时 msg 已过期
       * 暂时以 localstorage 中的信息作为登录依据
       */
      const localInfo = {
        key_token: localStore.get('key_token'),
        uid: localStore.get('uid'),
        eid: localStore.get('eid')
      }
      state.loadingUserInfo = true
      if (localInfo.key_token && localInfo.uid && localInfo.eid) {
        state.userInfo.key_token = localInfo.key_token
        state.userInfo.userId = localInfo.uid
        state.userInfo.enterpriseId = localInfo.eid
        getUserAccountInfo()
          .then((res) => {
            const userInfo = res.data.data
            state.userInfo.isAuth = userInfo.isAuth
            state.userInfo.name = userInfo.name || ''
            state.userInfo.account = userInfo.account || ''
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            state.loadingUserInfo = false
          })
      } else {
        const { msg, userId, enterpriseId } = getValueFromUrlQuery([
          'msg',
          'userId',
          'enterpriseId'
        ])
        try {
          const param = {
            msg
            // userId,
            // companyId: enterpriseId
          }
          const { data } = await getToken(param)
          const { token, uid, eid } = data
          const key_token = token
          localStore.set('key_token', key_token)
          localStore.set('uid', uid)
          localStore.set('eid', eid)
          state.userInfo.key_token = key_token
          state.userInfo.userId = uid
          state.userInfo.enterpriseId = eid
          try {
            const userInfoRes = await getUserAccountInfo()
            const userInfo = userInfoRes.data.data
            state.userInfo.isAuth = userInfo.isAuth
            state.userInfo.name = userInfo.name || ''
            state.userInfo.account = userInfo.account || ''
          } catch (err) {
            throw err
          }
        } catch (err) {
          console.error(err)

        } finally {

          state.loadingUserInfo = false
        }
      }
    }
  }
})
