import dark from '@/assets/style/themes/dark.scss'
import { Theme } from '@/assets/style/themes/index.scss'
import light from '@/assets/style/themes/light.scss'
import { Dictionary } from '@/utils/types'

type D<T = any> = Dictionary<T>
type HexColor = string

const themes: D<Theme> = {
  dark,
  light
}

/**
 * 引入从 scss 文件中导出的变量, 调整所有变量的 key 为 css 变量的格式并导出
 */
function themeObjToCssVariable(t: Theme): D<HexColor> {
  return Object.keys(t).reduce(
    (res, key: string) =>
      Object.assign(res, { [`--${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`]: t[key] }),
    {}
  )
}

export default Object.keys(themes).reduce<D<D<HexColor>>>((res, key) => {
  res[key] = themeObjToCssVariable(themes[key])
  return res
}, {})
