// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#ffffff",
	"menuText": "#1A1A1A",
	"menuActiveText": "#F45A51",
	"menuActiveBg": "#FFF5F5",
	"submenuActiveText": "#1A1A1A",
	"submenuBg": "#ffffff",
	"submenuHover": "#FFF5F5",
	"submenuTextHover": "#F45A51",
	"logoBg": "#fff"
};
module.exports = exports;
