
import { Component, Vue } from 'vue-property-decorator'
import ruralLogo from '@/assets/logo/favicon.png'

@Component({
  name: 'SidebarLogo'
})
export default class extends Vue {
  private ruralLogo: any = ruralLogo
  private systemType: any = process.env.VUE_APP_SYSTEM_TYPE
}
