import request, { request as http } from '@/utils/request'
import { AxiosResponseForZCT, GetUserAccountInfoRes, Res, ZCTAPI } from '@/utils/types'
import { DelistingParams } from '@/views/type'
import { AxiosPromise } from 'axios'

export function getToken(data: { msg: string }) {
  return http<AxiosResponseForZCT>({
    url: '/admin/loginNew',
    method: 'GET',
    params: data
  })
}

// 获取账号信息
export function getUserAccountInfo(): AxiosPromise<Res<GetUserAccountInfoRes>> {
  return request({
    url: '/sys/user/userAccountInfo',
    method: 'POST',
    data: {}
  })
}

// 获取产权列表
export function getPropertyRightList(
  data: ZCTAPI.PropertyRightListParam
): AxiosPromise<ZCTAPI.Res<ZCTAPI.PropertyRightListItem[]>> {
  return request({
    url: '/sys/examine/getAllCommoditiesTrading',
    method: 'POST',
    data
  })
}

// 获取产权详情
export function getPropertyRightDetail(data: {
  id: string
}): AxiosPromise<ZCTAPI.Res<ZCTAPI.PropertyRightDetail>> {
  return request({
    url: '/sys/examine/getCommoditiesTradingDetail',
    method: 'POST',
    data
  })
}

// 获取金融项目列表
export function getFinancialList(
  data: ZCTAPI.FinancialListParam
): AxiosPromise<ZCTAPI.Res<ZCTAPI.FinancialListItem[]>> {
  return request({
    url: '/directionalFinancing/page',
    method: 'POST',
    data
  })
}

// 获取金融项目详情
export function getFinancialDetail(data: {}): AxiosPromise<ZCTAPI.Res<ZCTAPI.FinancialDetail>> {
  return request({
    url: '/directionalFinancing/getDirectionalFinancingDetail',
    method: 'POST',
    data
  })
}

// 获取权证审核列表
export function getWarrantAuditList(data: {}): AxiosPromise<
  ZCTAPI.Res<ZCTAPI.WarrantAuditListItem[]>
> {
  return request({
    url: '/warrantAudit/getWarrantAuditList',
    method: 'POST',
    data
  })
}

// 获取权证审核详情
export function getWarrantAuditDetail(data: {
  id: number
}): AxiosPromise<ZCTAPI.Res<ZCTAPI.WarrantAuditDetail>> {
  return request({
    url: '/warrantAudit/warrantAuditDetail',
    method: 'POST',
    data
  })
}

// 通过/拒绝权证审核
export function makeWarrantAuditDecision(
  data: ZCTAPI.MakeWarrantAuditDecisionParam
): AxiosPromise<ZCTAPI.Res> {
  return request({
    url: '/warrantAudit/examineWarrantAudit',
    method: 'POST',
    data
  })
}

// 获取报名信息列表
export function getMyRegistrationTableData(data: {
  projectId: number
}): AxiosPromise<ZCTAPI.Res<ZCTAPI.MyRegistrationListItem[]>> {
  return request({
    url: '/examine/getExamineRegistrationList',
    method: 'POST',
    data
  })
}

// 获取竞价信息列表
export function getBiddingPriceTableData(data: {
  projectId: number
}): AxiosPromise<ZCTAPI.Res<ZCTAPI.MyBiddingPriseListRes>> {
  return request({
    url: '/examine/examineBiddingPriceList',
    method: 'POST',
    data
  })
}

// 设为中标人
export function setResultBidder(data: { id: number; projectId: number }): AxiosPromise<ZCTAPI.Res> {
  return request({
    url: '/examine/examineBiddingPrice',
    method: 'POST',
    data
  })
}

// 获取权证审核通过或者拒绝
export function setExamineWarrantAudit(data: {}): AxiosPromise<ZCTAPI.Res<ZCTAPI.FinancialDetail>> {
  return request({
    url: '/warrantAudit/examineWarrantAudit',
    method: 'POST',
    data
  })
}

// 主动摘牌
export function delisting(data: DelistingParams) {
  return http<AxiosResponseForZCT>({
    url: '/sys/examine/delisting',
    method: 'POST',
    data
  })
}

// 产权项目 审核通过
export function passPropertyAudit(data: object) {
  return http<AxiosResponseForZCT>({
    url: '/sys/examine/examinePassCommoditiesTrading',
    method: 'POST',
    data
  })
}

// 产权项目 审核不通过
export function rejectPropertyAudit(data: object) {
  return http<AxiosResponseForZCT>({
    url: '/sys/examine/examineNotPassCommoditiesTrading',
    method: 'POST',
    data
  })
}

// 金融项目 审核通过
export function passFinancialAudit(data: object) {
  return http<AxiosResponseForZCT>({
    url: '/sys/examine/examinePassDirectionalFinancing',
    method: 'POST',
    data
  })
}

// 金融项目 审核不通过
export function rejectFinancialAudit(data: object) {
  return http<AxiosResponseForZCT>({
    url: '/sys/examine/examineNotPassDirectionalFinancing',
    method: 'POST',
    data
  })
}

// 签章接口
export function signContract(data: object) {
  return http<AxiosResponseForZCT>({
    url: '/signature/signContract',
    method: 'POST',
    data
  })
}

// 产权项目的投资信息
export function getInvestmentInfo(data: { projectId: number }) {
  return http<AxiosResponseForZCT>({
    url: '/examine/getInvestmentInfo',
    method: 'POST',
    data
  })
}
