// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.el-submenu.is-active > .el-submenu__title {\n  color: var(--submenu-active-text) !important;\n}\n.el-menu-item .is-active {\n  background-color: var(--menu-active-bg) !important;\n}\n.menu-wrapper .router-link-active {\n  display: inline-block;\n  width: 100%;\n  border-left: 6px solid #f45a51;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title,\n.full-mode .el-submenu .el-menu-item {\n  min-width: 240px !important;\n  background-color: var(--submenu-bg) !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title:hover,\n  .full-mode .el-submenu .el-menu-item:hover {\n    background-color: var(--submenu-hover) !important;\n    color: var(--submenu-text-hover) !important;\n}\n.full-mode .el-submenu__title i {\n  color: var(--menu-text) !important;\n}\n.full-mode .el-submenu .is-active {\n  background-color: #fff5f5 !important;\n  min-width: 234px !important;\n}\n.full-mode .router-link-active .is-active {\n  background-color: #fff5f5 !important;\n}\n.simple-mode.first-level .submenu-title-noDropdown {\n  padding: 0 !important;\n  position: relative;\n}\n.simple-mode.first-level .submenu-title-noDropdown .el-tooltip {\n    padding: 0 !important;\n}\n.simple-mode.first-level .el-submenu {\n  overflow: hidden;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title {\n    padding: 0 !important;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title .el-submenu__icon-arrow {\n      display: none;\n      right: 30px;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title > span {\n      visibility: hidden;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#001529",
	"menuText": "#fff",
	"menuActiveText": "#0062ff"
};
module.exports = exports;
