
// InjectReactive
import { Prop, Component, Vue, Watch } from 'vue-property-decorator'

import routes from '@/router/routes'
// import routes from '@/utils/meus'   //本地路由
import SidebarLogo from './SidebarLogo.vue'
import SidebarItem from './SidebarItem.vue'
import { RouteConfig } from 'vue-router'
import { SettingsModule } from '@/store/setting.store'
import { UserModule } from '@/store/user.store'
import { Theme } from '@/assets/style/themes/index.scss'
import { getRoleMenus } from '@/api/login'
import { localStore } from '@/utils/data-management'

@Component({
  name: 'Sidebar',
  components: {
    SidebarLogo,
    SidebarItem
  }
})
export default class extends Vue {
  @Prop() themeStyle!: Theme
  // @InjectReactive() themeStyle!: Theme
  private routesData: any = {}
  // 路由名字列表
  private nameObj: any = {}
  // 路由地址列表
  private pathArr: any = []
  // 路由名字记录下标（用于设置名字地址对应表方法）
  private nowInd: number = -1
  get routes() {
    // 目前仅对路由进行外层过滤, 对于复杂路由逻辑需要递归遍历初始化.
    let datas: any = []
    let routeDatass = this.$store.state.routeDatass
    if (routeDatass.datas && routeDatass.datas.length > 0) {
      datas = routeDatass.datas
    } else {
      datas = []
    }
    // return routes
    let returnData: any = datas.length > 0 ? datas : localStore.get('routes')
    returnData && this.setNametoPath(returnData)
    return returnData
  }

  get updates() {
    return this.$store.state.route
  }
  get activeMenu() {
    // 根据业务路由name，如果meta.activeMenu和name没有，就在nameObj找
    let returnName: any = this.$route.meta.activeMenu
    if (!returnName && this.$route.name) {
      returnName = this.$route.name
    }
    if (!returnName) {
      let nowPathInd: number = -1
      for (let i = 0; i < this.pathArr.length; i++) {
        if (this.pathArr[i] == this.$route.path) {
          nowPathInd = i
          break
        }
      }
      returnName = this.nameObj[nowPathInd]
    }
    return returnName
    // return this.$route.meta.activeMenu || this.$route.name
  }
  get isCollapse() {
    return SettingsModule.hideSidebar
  }
  // --------------watch监听-----------------------------
  @Watch('$store.state.routeArr')
  hasRouteArr(newValue: string, oldValue: string) {
    if (newValue === '1') {
      this.handleRoute()
    } else {
      this.handleLocalRoute()
    }
  }
  // watch监听------=--------------end--------
  created() {}
  // 获取路由name和path对照表（针对本地未配置路由）
  private setNametoPath(arr: any, fatherPath: any = '') {
    for (let i = 0; i < arr.length; i++) {
      this.nowInd = this.nowInd + 1
      let filtePath: string = arr[i].path.replace(/\/{2,}/g, '/'),
        hasFather: boolean = true
      if (filtePath[0] == '/') {
        hasFather = false
        filtePath = filtePath.slice(1)
      }
      filtePath[filtePath.length - 1] == '/' && (filtePath = filtePath.slice(0, filtePath.length - 1))
      let nowPath: any = fatherPath && hasFather ? '/' + fatherPath + '/' + filtePath : '/' + filtePath
      this.nameObj[this.nowInd] = arr[i].name
      this.pathArr.push(nowPath)
      arr[i].children && arr[i].children.length && this.setNametoPath(arr[i].children, arr[i].path)
    }
  }
  private handleLocalRoute() {
    let datas = []
    datas.push({
      path: '/',
      name: 'Root',
      redirect: '/home',
      children: [
        {
          path: '/home',
          name: 'Home',
          meta: {
            title: '首页',
            icon: 'star'
          }
        }
        // {
        //   path: 'center-management',
        //   name: 'CenterManagement',
        //   meta: { title: '个人中心', icon: 'star', alwaysShow: true },
        //   redirect: '/home',
        //   children: [
        //     {
        //       path: 'account-management',
        //       name: 'AccountManagement',
        //       meta: { title: '账号管理', icon: 'star' }
        //     },
        //     {
        //       path: 'bankcard-management',
        //       name: 'BankCardManagement',
        //       meta: { title: '银行卡管理', icon: 'star' }
        //     }
        //   ]
        // }
      ]
    })
    this.$store.commit({ type: 'ROUTE_UPDATE', datas: datas[0].children })
    localStore.set('routes', datas[0].children)
  }
  private handleRoute() {
    getRoleMenus().then((res) => {
      let datas: any = res.data.data
      datas.forEach((item: any) => {
        item.children.unshift({
          path: '/home',
          name: 'Home',
          meta: {
            title: '首页',
            icon: 'star'
          }
        })
      })
      this.$store.commit({ type: 'ROUTE_UPDATE', datas: datas[0].children })
      // ;(this.$router as any).options.routes = []
      // ;(this.$router as any).options.routes = datas
      localStore.set('routes', datas[0].children)
    })
  }
}
