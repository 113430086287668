// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.sidebar .el-scrollbar {\n  height: calc(100vh - 80px);\n}\n.sidebar .el-scrollbar .scrollbar-wrapper {\n    overflow-x: hidden !important;\n}\n.sidebar .el-scrollbar .el-scrollbar__view {\n    height: 100%;\n}\n.sidebar .el-scrollbar .el-scrollbar__bar.is-vertical {\n    right: 0;\n}\n.sidebar .el-scrollbar .el-scrollbar__bar.is-horizontal {\n    display: none;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#001529",
	"menuText": "#fff",
	"menuActiveText": "#0062ff"
};
module.exports = exports;
