import Layout from '@/layout/index.vue'
import { RouteConfig } from 'vue-router'

// const routerObj: any = {
//   Home: () => import('@/views/home.vue'),
//   projectManagement: () => import('@/views/index.vue')
// }

export default [
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录', icon: 'star' },
    component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: '注册', icon: 'star' },
    component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/register.vue')
  },
  {
    path: '/forgetpwd',
    name: 'ForgetPwd',
    meta: { title: '忘记密码', icon: 'star' },
    component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/forgetPassword.vue')
  },
  {
    path: '/',
    name: 'Root',
    component: Layout,
    children: [
      // 首页
      {
        path: '/home',
        name: 'Home',
        meta: { title: '首页', icon: 'star' },
        component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/home.vue')
      },
      //信用信息披露业务
      {
        path: 'project-management',
        name: 'ProjectManagement',
        meta: { title: '信用信息披露业务', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/index.vue'),
        children: [
          // 项目列表
          {
            path: 'financial',
            name: 'Financial',
            meta: { title: '项目列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/financial/index.vue')
          },
          {
            // path: 'financial-details/:type/:id/:listingMode',
            path: 'financial-details/:type/:listingMode',
            name: 'FinancialDetails',
            meta: {
              title: '项目详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['ProjectManagement', 'Financial', 'FinancialDetails'],
                activeMenu: 'Financial'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/financial/details.vue')
          },
          // 成立管理
          {
            path: 'examination-approval-list',
            name: 'ExaminationApprovalList',
            meta: { title: '成立管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/examination-approval/index.vue')
          },
          // {
          //   path: 'approval-detail/:id',
          //   name: 'ApprovalDetail',
          //   meta: {
          //     title: '成立审核详情',
          //     icon: 'star',
          //     standalone: {
          //       breadcrumb: ['ProjectManagement', 'ExaminationApprovalList', 'ApprovalDetail'],
          //       activeMenu: 'ExaminationApprovalList'
          //     }
          //   },
          //   component: () =>
          //     import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/examination-approval/approvalDetail.vue')
          // },
          // 审批管理
          {
            path: 'approval-management',
            name: 'ApprovalManagement',
            meta: {
              title: '审批管理',
              icon: 'star'
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/approval-management/index.vue')
          },
          {
            path: 'approval-setting/:ruleType',
            name: 'ApprovalSetting',
            meta: {
              title: '审批设置',
              icon: 'star',
              standalone: {
                breadcrumb: ['ProjectManagement', 'ApprovalManagement', 'ApprovalSetting'],
                activeMenu: 'ApprovalManagement'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/approval-management/approvalSetting.vue')
          },
          // 法律意见书
          {
            path: 'law-list',
            name: 'LawList',
            meta: { title: '法律意见书', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/law-list/index.vue')
          },
          {
            // path: 'law-list-detail/:type/:id',
            path: 'law-list-detail/:type',
            name: 'LawDetail',
            meta: {
              title: '项目详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['ProjectManagement', 'LawList', 'LawDetail'],
                activeMenu: 'LawList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/law-list/lawDetail.vue')
          },
          // 我的审批
          {
            path: 'center-list',
            name: 'CenterList',
            meta: { title: '我的审批', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/center-list/index.vue')
          },
          {
            // path: 'center-list-detail/:type/:id/:secondApproval/:bizType',
            path: 'center-list-detail/:type/:secondApproval/:bizType',
            name: 'CenterDetail',
            meta: {
              title: '审批详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['ProjectManagement', 'CenterList', 'CenterDetail'],
                activeMenu: 'CenterList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/center-list/centerDetail.vue')
          },
          // 备案企业管理
          {
            path: 'enterprise-management',
            name: 'EnterpriseManagement',
            meta: { title: '备案企业管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/enterprise-management/index.vue')
          },
          {
            path: 'enterprise-management-detail/:id',
            name: 'EnterpriseManagementDetail',
            meta: {
              title: '企业详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['ProjectManagement', 'EnterpriseManagement', 'EnterpriseManagementDetail'],
                activeMenu: 'EnterpriseManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/enterprise-management/detail.vue')
          },
          // 文字识别
          {
            path: 'ocr',
            name: 'Ocr',
            meta: { title: '文字识别', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/ocr/index.vue')
          },
          {
            path: 'ocr-detail/:type',
            name: 'OcrDetail',
            meta: {
              title: '文字识别详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['ProjectManagement', 'Ocr', 'OcrDetail'],
                activeMenu: 'Ocr'
              }
            },
            component: () => import('@/views/project-management/ocr/detail.vue')
          },
          {
            path: 'operation',
            name: 'Operation',
            meta: {
              title: '操作',
              icon: 'star',
              standalone: {
                breadcrumb: ['ProjectManagement', 'Ocr', 'OcrDetail', 'Operation'],
                activeMenu: 'Ocr'
              }
            },
            component: () => import('@/views/project-management/ocr/operation.vue')
          }
        ]
      },
      // 财务管理
      {
        path: 'financial-management',
        name: 'FinancialManagement',
        meta: { title: '财务管理', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/index.vue'),
        children: [
          // 财务列表
          {
            path: 'financial-list',
            name: 'FinancialList',
            meta: { title: '财务管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/financial-management/financial-list/index.vue')
          },
          {
            path: 'financial-detail',
            name: 'FinancialDetail',
            meta: {
              title: '缴费详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['FinancialManagement', 'FinancialList', 'FinancialDetail'],
                activeMenu: 'FinancialList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/financial-management/financial-list/detail.vue')
          },
          {
            path: 'trade-details',
            name: 'TradeDetails',
            meta: {
              title: '交易详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['FinancialManagement', 'FinancialList', 'TradeDetails'],
                activeMenu: 'FinancialList'
              }
            },
            component: () => import('@/views/financial-management/financial-list/trade-details.vue')
          },
          {
            path: 'trade-receiveList/:id',
            name: 'TradeReceiveList',
            meta: {
              title: '收款人列表',
              icon: 'star',
              standalone: {
                breadcrumb: ['FinancialManagement', 'FinancialList', 'TradeReceiveList'],
                activeMenu: 'FinancialList'
              }
            },
            component: () => import('@/views/financial-management/financial-list/trade-receiveList.vue')
          },
          {
            path: 'trade-receipt/:id',
            name: 'TradeReceipt',
            meta: {
              title: '交易凭证',
              icon: 'star',
              standalone: {
                breadcrumb: ['FinancialManagement', 'FinancialList', 'TradeReceipt'],
                activeMenu: 'FinancialList'
              }
            },
            component: () => import('@/views/financial-management/financial-list/trade-receipt.vue')
          }
        ]
      },
      // 结算业务
      {
        path: 'settlement-business',
        name: 'SettlementBusiness',
        meta: { title: '结算业务', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/index.vue'),
        children: [
          // 结算列表
          {
            path: 'settlement-list',
            name: 'SettlementList',
            meta: { title: '结算列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/settlement-business/settlement-list/index.vue')
          },
          // 结算详情
          {
            path: 'settlement-details',
            name: 'SettlementDetails',
            meta: {
              title: '结算详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['SettlementBusiness', 'SettlementList', 'SettlementDetails'],
                activeMenu: 'SettlementList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/settlement-business/settlement-list/details.vue')
          },
          // 审批管理
          {
            path: 'settlement-aplmanagement-list',
            name: 'SettlementAplmanagementList',
            meta: { title: '审批管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/settlement-business/approval-management/index.vue')
          },
          // 审批设置
          {
            path: 'settlement-aplmanagement-details/:ruleType',
            name: 'SettlementAplmanagementDetails',
            meta: {
              title: '审批设置',
              icon: 'star',
              standalone: {
                breadcrumb: ['SettlementBusiness', 'SettlementAplmanagementList', 'SettlementAplmanagementDetails'],
                activeMenu: 'SettlementAplmanagementList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/settlement-business/approval-management/details.vue')
          },
          // 我的审批
          {
            path: 'settlement-approval-list',
            name: 'SettlementApprovalList',
            meta: { title: '我的审批', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/settlement-business/my-approval/index.vue')
          },
          // 我的审批详情
          {
            path: 'settlement-approval-details',
            name: 'SettlementApprovalDetails',
            meta: {
              title: '审批详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['SettlementBusiness', 'SettlementApprovalList', 'SettlementApprovalDetails'],
                activeMenu: 'SettlementApprovalList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/settlement-business/my-approval/details.vue')
          }
        ]
      },
      //人员管理
      {
        path: 'personnel-management',
        name: 'PersonnelManagement',
        meta: { title: '人员管理', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          // ----------------------------人员管理---------------------
          {
            path: 'personnel-list',
            name: 'PersonnelList',
            meta: { title: '人员列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/personnel-management/personnel-list/index.vue')
          },
          {
            path: 'personnel-list-detail/:flag',
            name: 'PersonnelListDetail',
            meta: {
              title: '查看详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['PersonnelManagement', 'PersonnelList', 'PersonnelListDetail'],
                activeMenu: 'PersonnelList'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/personnel-management/personnel-list/personnelDetail.vue')
          },
          // ----------------------------角色管理----------------------
          {
            path: 'role-management',
            name: 'RoleManagement',
            meta: { title: '角色管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/personnel-management/role-management/index.vue')
          },
          {
            // path: 'role-management-detail/:id',
            path: 'role-management-detail',
            name: 'RoleManagementDetail',
            meta: {
              title: '角色详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['PersonnelManagement', 'RoleManagement', 'RoleManagementDetail'],
                activeMenu: 'RoleManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/personnel-management/role-management/roleDetail.vue')
          }
        ]
      },
      //信息管理
      {
        path: 'notice-management',
        name: 'NoticeManagement',
        meta: { title: '信息管理', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          {
            path: 'project-notice',
            name: 'ProjectNotice',
            meta: { title: '信息发布', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/project-notice/index.vue')
          },
          // {
          //   path: 'add-project-notice',
          //   name: 'AddProjectNotice',
          //   meta: {
          //     title: '新增项目公告',
          //     icon: 'star',
          //     standalone: {
          //       breadcrumb: ['NoticeManagement', 'ProjectNotice', 'AddProjectNotice'],
          //       activeMenu: 'ProjectNotice'
          //     }
          //   },
          //   component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/project-notice/addNotice.vue')
          // },
          {
            path: 'details-project-notice/:id',
            name: 'DetailsProjectNotice',
            meta: {
              title: '信息发布详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['NoticeManagement', 'ProjectNotice', 'DetailsProjectNotice'],
                activeMenu: 'ProjectNotice'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/project-notice/addNotice.vue')
          },
          {
            path: 'banner-management',
            name: 'BannerManagement',
            meta: { title: 'banner管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/banner-management/index.vue')
          },
          {
            path: 'banner-management-detail/:id',
            name: 'BannerManagementDetail',
            meta: {
              title: 'banner详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['NoticeManagement', 'BannerManagement', 'BannerManagementDetail'],
                activeMenu: 'BannerManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/banner-management/detail.vue')
          },
          {
            path: 'mortgage-notice',
            name: 'MortgageNotice',
            meta: { title: '抵押公告', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/mortgage-notice/index.vue')
          },
          {
            path: 'add-mortgage-notice/:id',
            name: 'AddMortgageNotice',
            meta: {
              title: '抵押公告详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['NoticeManagement', 'MortgageNotice', 'AddMortgageNotice'],
                activeMenu: 'MortgageNotice'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/mortgage-notice/addNotice.vue')
          },
          {
            path: 'feedback-management',
            name: 'FeedbackManagement',
            meta: { title: '意见反馈管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/feedback-management/index.vue')
          },
          {
            path: 'feedback-management-detail',
            name: 'FeedbackManagementDetail',
            meta: {
              title: '意见反馈详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['NoticeManagement', 'FeedbackManagement', 'FeedbackManagementDetail'],
                activeMenu: 'FeedbackManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/feedback-management/feedbackManagementDetail.vue')
          }
          // {
          //   path: 'details-mortgage-notice/:id',
          //   name: 'DetailsMortgageNotice',
          //   meta: {
          //     title: '抵押公告详情',
          //     icon: 'star',
          //     standalone: {
          //       breadcrumb: ['NoticeManagement', 'MortgageNotice', 'DetailsMortgageNotice'],
          //       activeMenu: 'MortgageNotice'
          //     }
          //   },
          //   component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/notice-management/mortgage-notice/details.vue')
          // }
        ]
      },
      // 农户信息管理
      {
        path: 'peasant-info-management',
        name: 'PeasantInfoManagement',
        meta: { title: '农户信息管理', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          // 村组管理列表
          {
            path: 'village-list',
            name: 'VillageList',
            meta: { title: '村组管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/village-list/index.vue')
          },
          {
            path: 'village-detail/:type',
            name: 'VillageDetail',
            meta: {
              title: '村组管理详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantInfoManagement', 'VillageList', 'VillageDetail'],
                activeMenu: 'VillageList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/village-list/villageDetail.vue')
          },
          // 农户信息列表
          {
            path: 'farmer-info-list',
            name: 'FarmerInfoList',
            meta: { title: '农户信息列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/farmer-info-list/index.vue')
          },
          {
            // path: 'farmer-info-detail/:type/:farmersInfoId',
            path: 'farmer-info-detail/:type',
            name: 'FarmerInfoDetail',
            meta: {
              title: '农户信息详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantInfoManagement', 'FarmerInfoList', 'FarmerInfoDetail'],
                activeMenu: 'FarmerInfoList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/farmer-info-list/farmerInfoDetail.vue')
          },
          // 农户信息导入
          {
            path: 'farmer-info-export',
            name: 'FarmerInfoExport',
            meta: { title: '农户信息导入', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/farmer-info-export/index.vue')
          },
          // 待审批农户信息
          {
            path: 'pending-approval-list',
            name: 'PendingApprovalList',
            meta: { title: '待审批农户信息', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/pending-approval-list/index.vue')
          },
          {
            // path: 'pending-approval-detail/:farmersInfoId/:approvalId',
            path: 'pending-approval-detail',
            name: 'PendingApprovalDetail',
            meta: {
              title: '待审批农户信息详情', icon: 'star',
              standalone: {
                breadcrumb: ['PeasantInfoManagement', 'PendingApprovalList', 'PendingApprovalDetail'],
                activeMenu: 'PendingApprovalList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/pending-approval-list/pendingApprovalDetail.vue')
          },
          // 信息收集模板配置
          {
            path: 'info-template-config',
            name: 'InfoTemplateConfig',
            meta: { title: '信息收集模板配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/info-template-config/index.vue')
          },
          {
            path: 'info-template-configdetail',
            name: 'InfoTemplateConfigDetail',
            meta: {
              title: '信息收集模板配置详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantInfoManagement', 'InfoTemplateConfig', 'InfoTemplateConfigDetail'],
                activeMenu: 'InfoTemplateConfig'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/info-template-config/infoTemplateConfigDetail.vue')
          }
          // // 补贴管理
          // {
          //   path: 'subsidy-management',
          //   name: 'SubsidyManagement',
          //   meta: { title: '补贴管理', icon: 'star' },
          //   component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-info-management/subsidy-management/index.vue')
          // },
          // {
          //   path: 'subsidy-detail',
          //   name: 'SubsidyDetail',
          //   meta: {
          //     title: '缴费详情',
          //     icon: 'star',
          //     standalone: {
          //       breadcrumb: ['PeasantInfoManagement', 'SubsidyManagement', 'SubsidyDetail',],
          //       activeMenu: 'SubsidyManagement'
          //     }
          //   },
          //   component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/financial-management/subsidy-management/subsidyDetail.vue')
          // }
        ]
      },
      // 农户补贴管理
      {
        path: 'peasant-subsidy-management',
        name: 'PeasantSubsidyManagement',
        meta: { title: '农户补贴管理', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          // 农户补贴列表
          {
            path: 'subsidy-list',
            name: 'SubsidyList',
            meta: { title: '农户补贴列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-list/index.vue')
          },
          {
            path: 'subsidy-add-type/:type',
            name: 'SubsidyAddType',
            meta: {
              title: '添加补贴',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyList', 'SubsidyAddType'],
                activeMenu: 'SubsidyList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-list/subsidyAddType.vue')
          },
          {
            path: 'subsidy-add/:id/:type',
            name: 'SubsidyAdd',
            meta: {
              title: '添加补贴',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyList', 'SubsidyAdd'],
                activeMenu: 'SubsidyList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-list/subsidyAdd.vue')
          },
          {
            path: 'subsidy-import/:type',
            name: 'SubsidyImport',
            meta: {
              title: '导入补贴',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyList', 'SubsidyImport'],
                activeMenu: 'SubsidyList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-list/subsidyImport.vue')
          },
          {
            path: 'subsidy-import-list/:url/:type',
            name: 'SubsidyImportList',
            meta: {
              title: '查看补贴列表',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyList', 'SubsidyImport', 'SubsidyImportList'],
                activeMenu: 'SubsidyList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-list/subsidyImportList.vue')
          },
          {
            path: 'subsidy-detail/:id/:index/:type',
            name: 'SubsidyDetail',
            meta: {
              title: '查看补贴',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyList', 'SubsidyDetail'],
                activeMenu: 'SubsidyList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-list/subsidyDetail.vue')
          },
          {
            path: 'subsidy-aduit/:id/:index/:type',
            name: 'SubsidyAduit',
            meta: {
              title: '审核补贴',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyList', 'SubsidyAduit'],
                activeMenu: 'SubsidyList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-list/subsidyDetail.vue')
          },
          {
            path: 'subsidy-grant',
            name: 'SubsidyGrant',
            meta: {
              title: '补贴发放',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyList', 'SubsidyGrant'],
                activeMenu: 'SubsidyList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-list/subsidyGrant.vue')
          },
          // 补贴发放记录
          {
            path: 'subsidy-grant-list',
            name: 'SubsidyGrantList',
            meta: { title: '补贴发放记录', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-grant-list/index.vue')
          },
          {
            path: 'subsidy-grant-detail/:id/:grantSuccess',
            name: 'SubsidyGrantDetail',
            meta: {
              title: '补贴发放详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyGrantList', 'SubsidyGrantDetail'],
                activeMenu: 'SubsidyGrantList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-grant-list/SubsidyGrantDetail.vue')
          },
          {
            path: 'subsidy-voucher/:id',
            name: 'SubsidyVoucher',
            meta: {
              title: '交易凭证',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyGrantList', 'SubsidyVoucher'],
                activeMenu: 'SubsidyGrantList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-grant-list/trade-receipt.vue')
          },
          // 补贴项目管理
          {
            path: 'subsidy-project-management',
            name: 'SubsidyProjectManagement',
            meta: { title: '补贴项目管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-project-management/index.vue')
          },
          {
            path: 'subsidy-project-detail/:type/:id',
            name: 'SubsidyProjectDetail',
            meta: {
              title: '补贴项目详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'SubsidyProjectManagement', 'SubsidyProjectDetail'],
                activeMenu: 'SubsidyProjectManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/subsidy-project-management/subsidyProjectDetail.vue')
          },
          // 黑名单管理
          {
            path: 'blacklist-management',
            name: 'BlacklistManagement',
            meta: { title: '黑名单管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/blacklist-management/index.vue')
          },
          {
            path: 'blacklist-add/:businessType',
            name: 'BlacklistAdd',
            meta: {
              title: '添加黑名单',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'BlacklistManagement', 'BlacklistAdd'],
                activeMenu: 'BlacklistManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/blacklist-management/blacklistDetail.vue')
          },
          {
            path: 'blacklist-detail/:id',
            name: 'BlacklistDetail',
            meta: {
              title: '查看黑名单',
              icon: 'star',
              standalone: {
                breadcrumb: ['PeasantSubsidyManagement', 'BlacklistManagement', 'BlacklistDetail'],
                activeMenu: 'BlacklistManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/blacklist-management/blacklistDetail.vue')
          }
        ]
      },
      // 农村产权交易
      {
        path: 'countryside-property-transaction',
        name: 'CountrysidePropertyTransaction',
        meta: { title: '农村产权交易', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          // 项目列表
          {
            path: 'countryside-project-list',
            name: 'CountrysideProjectList',
            meta: { title: '项目列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/countryside-project-list/index.vue')
          },
          // 项目详情
          {
            // path: 'countryside-project-detail/:listingApplyId',
            path: 'countryside-project-detail/:isExport',
            name: 'CountrysideProjectDetail',
            meta: {
              title: '项目详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'CountrysideProjectList', 'CountrysideProjectDetail'],
                activeMenu: 'CountrysideProjectList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/countryside-project-list/projectListsDetail.vue')
          },
          // 竞买人详情
          {
            path: 'bidders-detail',
            name: 'BiddersDetail',
            meta: {
              title: '竞买人详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'CountrysideProjectList', 'BiddersDetail'],
                activeMenu: 'CountrysideProjectList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/countryside-project-list/biddersDetail.vue')
          },
          // 审批管理
          {
            path: 'countryside-approval-management',
            name: 'CountrysideApprovalManagement',
            meta: { title: '审批管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/countryside-approval-management/index.vue')
          },
          // 审批设置详情
          {
            path: 'countryside-approval-detail/:ruleType/:areaConfigId',
            name: 'CountrysideApprovalDetail',
            meta: {
              title: '审批设置',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'CountrysideApprovalManagement', 'CountrysideApprovalDetail'],
                activeMenu: 'CountrysideApprovalManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/countryside-approval-management/countrysideApprovalDetail.vue')
          },
          // 鉴证书管理
          {
            path: 'authentication-management',
            name: 'AuthenticationManagement',
            meta: { title: '鉴证书管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/authentication-management/index.vue')
          },
          // 鉴证书申请详情
          {
            path: 'authentication-application-detail',
            name: 'AuthenticationApplicationDetail',
            meta: {
              title: '鉴证书申请详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'AuthenticationManagement', 'AuthenticationApplicationDetail'],
                activeMenu: 'AuthenticationManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/authentication-management/authenticationApplicationDetail.vue')
          },
          // 我的审批
          {
            path: 'my-approval',
            name: 'MyApproval',
            meta: { title: '我的审批', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/my-approval/index.vue')
          },
          // 项目导入列表
          {
            path: 'project-import',
            name: 'ProjectImport',
            meta: { title: '线下项目导入', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/project-import/index.vue')
          },
          // 线下项目列表
          {
            path: 'project-offline',
            name: 'ProjectOffline',
            meta: { title: '线下项目列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/project-offline/index.vue')
          },
          // 线下项目详情
          {
            path: 'project-offline-detail',
            name: 'ProjectOfflineDetail',
            meta: {
              title: '项目详情', icon: 'star', standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'ProjectOffline', 'ProjectOfflineDetail'],
                activeMenu: 'ProjectOffline'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/project-offline/projectOfflineDetail.vue')
          },
          // 挂牌审批
          {
            path: 'my-approval-detail',
            name: 'MyApprovalDetail',
            meta: {
              title: '审批详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'MyApproval', 'MyApprovalDetail'],
                activeMenu: 'MyApproval'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/my-approval/myApprovalDetail.vue')
          },
          // 项目限制管理
          {
            path: 'project-limit-management',
            name: 'ProjectLimitManagement',
            meta: { title: '项目限制管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/project-limit-management/index.vue')
          },
          // 添加限制人
          {
            // path: 'project-limit-detail/:type/:listingApplyId',
            path: 'project-limit-detail/:type',
            name: 'ProjectLimitDetail',
            meta: {
              title: '项目限制详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'ProjectLimitManagement', 'ProjectLimitDetail'],
                activeMenu: 'ProjectLimitManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/project-limit-management/projectLimitDetail.vue')
          },
          // 保证金管理列表
          {
            path: 'project-bond-management',
            name: 'ProjectBondManagement',
            meta: { title: '项目保证金管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/project-bond-management/index.vue')
          },
          // 保证金详情
          {
            path: 'bond-management-detail',
            name: 'BondManagementDetail',
            meta: {
              title: '项目保证金详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'ProjectBondManagement', 'BondManagementDetail'],
                activeMenu: 'ProjectBondManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/project-bond-management/bondManagementDetail.vue')
          },
          // 清算管理
          {
            path: 'liquidation-management',
            name: 'LiquidationManagement',
            meta: { title: '清算管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/liquidation-management/index.vue')
          },
          // 清算详情
          {
            path: 'liquidation-detail',
            name: 'LiquidationDetail',
            meta: {
              title: '清算详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'LiquidationManagement', 'LiquidationDetail'],
                activeMenu: 'LiquidationManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/liquidation-management/liquidationDetail.vue')
          },
          // // 保证金退款记录列表
          // {
          //   path: 'bond-return-record',
          //   name: 'BondReturnRecord',
          //   meta: { title: '保证金退还记录', icon: 'star' },
          //   component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/bond-return-record/index.vue')
          // },
          // // 保证金退款记录详情
          // {
          //   path: 'bond-record-detail',
          //   name: 'BondRecordDetail',
          //   meta: {
          //     title: '退还详情',
          //     icon: 'star',
          //     standalone: {
          //       breadcrumb: ['CountrysidePropertyTransaction', 'BondReturnRecord', 'BondRecordDetail'],
          //       activeMenu: 'BondReturnRecord'
          //     }
          //   },
          //   component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/bond-return-record/bondRecordDetail.vue')
          // },
          // 财务管理列表
          {
            path: 'countryside-financial-management',
            name: 'CountrysideFinancialManagement',
            meta: { title: '财务管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/countryside-financial-management/index.vue')
          },
          // 缴费详情
          {
            // path: 'countryside-financial-details/:delistingAmountFlowId',
            path: 'countryside-financial-details',
            name: 'CountrysideFinancialDetails',
            meta: {
              title: '缴费详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'CountrysideFinancialManagement', 'CountrysideFinancialDetails'],
                activeMenu: 'CountrysideFinancialManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/countryside-financial-management/countrysideFinancialDetails.vue')
          },
          // 摘牌方式授权审批
          {
            path: 'delisting-power-approval',
            name: 'DelistingPowerApproval',
            meta: { title: '摘牌方式授权审批', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/delisting-power-approval/index.vue')
          },
          // 摘牌方式授权审批详情
          {
            path: 'delisting-power-approval-details',
            name: 'DelistingPowerApprovalDetails',
            meta: {
              title: '审批详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'DelistingPowerApproval', 'DelistingPowerApprovalDetails'],
                activeMenu: 'DelistingPowerApproval'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/delisting-power-approval/details.vue')
          },
          // 交易机构管理
          {
            path: 'organizational-management-list',
            name: 'OrganizationalManagementList',
            meta: { title: '交易机构管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/organizational-management/index.vue')
          },
          // 交易机构详情
          {
            path: 'organizational-management-detail/:type',
            name: 'OrganizationalManagementDetail',
            meta: {
              title: '交易机构详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CountrysidePropertyTransaction', 'OrganizationalManagementList', 'OrganizationalManagementDetail'],
                activeMenu: 'OrganizationalManagementList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/countryside-property-transaction/organizational-management/organizationalDetail.vue')
          }
        ]
      },
      // 个人中心
      {
        path: 'center-management',
        name: 'CenterManagement',
        meta: { title: '个人中心', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          // 账号管理
          {
            path: 'account-management',
            name: 'AccountManagement',
            meta: { title: '账号管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/center-management/account-management/index.vue')
          },
          // 账号详情
          {
            path: 'verify-detail/:isPass',
            name: 'VerifyDetail',
            meta: {
              title: '账号详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CenterManagement', 'AccountManagement', 'VerifyDetail'],
                activeMenu: 'AccountManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/center-management/account-management/verifyDetail.vue')
          },
          // 银行卡管理
          {
            path: 'bankcard-management',
            name: 'BankCardManagement',
            meta: { title: '银行卡管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/center-management/bankcard-management/index.vue')
          },
          // 银行业务管理（企业/系统管理员）
          {
            path: 'company-bank-management',
            name: 'CompanyBankManagement',
            meta: { title: '银行业务管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/center-management/company-bankbusiness/index.vue')
          },
          {
            path: 'company-bank-business',
            name: 'CompanyBankBusiness',
            meta: {
              title: '添加业务',
              icon: 'star',
              standalone: {
                breadcrumb: ['CenterManagement', 'CompanyBankManagement', 'CompanyBankBusiness'],
                activeMenu: 'CompanyBankManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/center-management/company-bankbusiness/companyBankBusiness.vue')
          },
          {
            path: 'company-importbank-business',
            name: 'CompanyImportBankBusiness',
            meta: {
              title: '导入业务',
              icon: 'star',
              standalone: {
                breadcrumb: ['CenterManagement', 'CompanyBankManagement', 'CompanyImportBankBusiness'],
                activeMenu: 'CompanyBankManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/center-management/company-bankbusiness/importBankDataDetail.vue')
          }
        ]
      },
      // 系统配置
      {
        path: 'system-config',
        name: 'SystemConfig',
        meta: { title: '系统配置', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/index.vue'),
        children: [
          // 文件配置
          {
            path: 'file-config',
            name: 'FileConfig',
            meta: { title: '文件配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/file-config/index.vue')
          },
          {
            path: 'config-add-file',
            name: 'configAddFile',
            meta: {
              title: '新增文件',
              icon: 'star',
              standalone: {
                breadcrumb: ['SystemConfig', 'FileConfig', 'configAddFile'],
                activeMenu: 'FileConfig'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/file-config/addFile.vue')
          },
          // 数据字典管理
          {
            path: 'dictionary-list',
            name: 'DictionaryList',
            meta: { title: '数据字典管理', icon: 'star' },
            component: () => import('@/views/system-config/dictionary-config/index.vue')
          },
          {
            path: 'dictionary-add',
            name: 'DictionaryAdd',
            meta: {
              title: '数据字典添加',
              icon: 'star',
              standalone: {
                breadcrumb: ['SystemConfig', 'DictionaryList', 'DictionaryAdd'],
                activeMenu: 'DictionaryList'
              }
            },
            component: () => import('@/views/system-config/dictionary-config/insert.vue')
          },
          // 签章配置
          {
            path: 'signature-config',
            name: 'SignatureConfig',
            meta: { title: '签章配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/signature-config/index.vue')
          },
          {
            path: 'collectionAndpayment-config',
            name: 'collectionAndpaymentConfig',
            meta: { title: '代收付配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/collectionAndpaymentConfig/index.vue')
          },
          // 区域配置
          {
            path: 'area-config',
            name: 'AreaConfig',
            meta: { title: '区域配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/area-config/index.vue')
          },
          {
            path: 'area-insert',
            name: 'AreaInsert',
            meta: {
              title: '区域配置',
              icon: 'star',
              standalone: {
                breadcrumb: ['SystemConfig', 'AreaConfig', 'AreaInsert'],
                activeMenu: 'AreaConfig'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/system-config/area-config/insert.vue')
          },
          // 系统银行卡管理
          {
            path: 'system-bankcard-management',
            name: 'SystemBankCardManagement',
            meta: { title: '系统银行卡管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/system-bankcard-management/index.vue')
          },
          // 系统银行业务管理
          {
            path: 'system-bank-management',
            name: 'SystemBankManagement',
            meta: { title: '系统银行业务管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/system-bank-management/index.vue')
          },
          {
            path: 'add-bank-management',
            name: 'AddBankManagement',
            meta: {
              title: '添加业务',
              icon: 'star',
              standalone: {
                breadcrumb: ['SystemConfig', 'SystemBankManagement', 'AddBankManagement'],
                activeMenu: 'SystemBankManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/system-bank-management/addBankManagement.vue')
          },
          {
            path: 'import-bank-business',
            name: 'ImportBankBusiness',
            meta: {
              title: '导入业务',
              icon: 'star',
              standalone: {
                breadcrumb: ['SystemConfig', 'SystemBankManagement', 'ImportBankBusiness'],
                activeMenu: 'SystemBankManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/system-bank-management/importBankBusiness.vue')
          },
          // 菜单配置
          {
            path: 'menu-config',
            name: 'MenuConfig',
            meta: { title: '菜单配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/menu-config/index.vue')
          },
          // 权限配置
          {
            path: 'power-config',
            name: 'PowerConfig',
            meta: { title: '权限配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/power-config/index.vue')
          },
          {
            path: 'collection-fee-config',
            name: 'CollectionFeeConfig',
            meta: { title: '收款银行卡配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/collection-fee-config/index.vue')
          },
          {
            path: 'rural-collection-fee-config',
            name: 'RuralCollectionFeeConfig',
            meta: { title: '收款费项配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/rural-collection-fee-config/index.vue')
          },
          {
            path: 'trading-rules-config',
            name: 'TradingRulesConfig',
            meta: { title: '交易规则配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/system-config/trading-rules-config/index.vue')
          },
          // 挂牌在线授权协议
          {
            path: 'licensing-agreement',
            name: 'LicensingAgreement',
            meta: { title: '挂牌在线授权协议', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/system-config/licensing-agreement/index.vue')
          },
          // 业务类型配置
          {
            path: 'business-type-config',
            name: 'BusinessTypeConfig',
            meta: { title: '业务类型配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/business-type-config/index.vue')
          },
          {
            path: 'business-type-detail/:id',
            name: 'BusinessTypeDetail',
            meta: {
              title: '业务类型配置详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['SystemConfig', 'BusinessTypeConfig', 'BusinessTypeDetail'],
                activeMenu: 'BusinessTypeConfig'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/system-config/business-type-config/detail.vue')
          },
        ]
      },
      // 会员管理
      {
        path: 'vip-management',
        name: 'VipManagement',
        meta: { title: '会员管理', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/index.vue'),
        children: [
          {
            path: 'vip-list',
            name: 'VipList',
            meta: { title: '会员列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/vip-management/vip-list/index.vue')
          },
          {
            path: 'vip-list-detail/:type/:id',
            name: 'VipDetail',
            meta: {
              title: '会员详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['VipManagement', 'VipList', 'VipDetail'],
                activeMenu: 'CenterList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/vip-management/vip-list/vipDetail.vue')
          },
          {
            path: 'vip-check-list',
            name: 'VipCheckList',
            meta: { title: '非投资类会员审核', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/vip-management/vip-check-list/index.vue')
          },
          {
            path: 'vip-check-detail/:type/:id',
            name: 'vipCheckDetail',
            meta: {
              title: '审核详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['VipManagement', 'VipCheckList', 'vipCheckDetail'],
                activeMenu: 'VipCheckList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/vip-management/vip-check-list/vipCheckDetail.vue')
          }
        ]
      },
      // 贷款业务管理
      {
        path: 'loan-transaction-management',
        name: 'LoanTransactionManagement',
        meta: { title: '贷款业务管理', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          // 贷款产品管理
          {
            path: 'loan-products',
            name: 'LoanProducts',
            meta: { title: '贷款产品管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/loan-transaction-management/loan-products/index.vue')
          },
          {
            path: 'loan-products-detail/:disabled/:flag',
            name: 'LoanProductsDetail',
            meta: {
              title: '查看详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['LoanTransactionManagement', 'LoanProducts', 'LoanProductsDetail'],
                activeMenu: 'LoanProducts'
              }
            },
            component: () =>
              import( /* webpackChunkName: 'ImportRouterPath' */ '@/views/loan-transaction-management/loan-products/productsDetail.vue')
          },
          // 贷款产品审批
          {
            path: 'products-approval',
            name: 'ProductsApproval',
            meta: { title: '贷款产品审批', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/loan-transaction-management/products-approval/index.vue')
          },
          {
            path: 'loan-approval-detail/:permission',
            name: 'ApprovalDetail',
            meta: {
              title: '查看详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['LoanTransactionManagement', 'ProductsApproval', 'ApprovalDetail'],
                activeMenu: 'ProductsApproval'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/loan-transaction-management/products-approval/approvalDetail.vue')
          },
          // 抵押贷款审批
          {
            path: 'mortgage-approval',
            name: 'MortgageApproval',
            meta: { title: '抵押贷款审批', icon: 'star' },
            component: () => import( /* webpackChunkName: 'ImportRouterPath' */ '@/views/loan-transaction-management/mortgage-approval/index.vue')
          },
          {
            path: 'mortgage-detail/:permission',
            name: 'MortgageDetail',
            meta: {
              title: '查看详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['LoanTransactionManagement', 'MortgageApproval', 'MortgageDetail'],
                activeMenu: 'MortgageApproval'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/loan-transaction-management/mortgage-approval/mortgageDetail.vue')
          },
          // 黑名单管理
          {
            path: 'loan-blacklist-management',
            name: 'LoanBlacklistManagement',
            meta: { title: '黑名单管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */ '@/views/peasant-subsidy-management/blacklist-management/index.vue')
          },
          {
            path: 'loan-blacklist-add/:businessType',
            name: 'LoanBlacklistAdd',
            meta: {
              title: '添加黑名单',
              icon: 'star',
              standalone: {
                breadcrumb: ['LoanTransactionManagement', 'LoanBlacklistManagement', 'LoanBlacklistAdd'],
                activeMenu: 'LoanBlacklistManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/blacklist-management/blacklistDetail.vue')
          },
          {
            path: 'loan-blacklist-detail/:id',
            name: 'LoanBlacklistDetail',
            meta: {
              title: '查看黑名单',
              icon: 'star',
              standalone: {
                breadcrumb: ['LoanTransactionManagement', 'LoanBlacklistManagement', 'LoanBlacklistDetail'],
                activeMenu: 'LoanBlacklistManagement'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/peasant-subsidy-management/blacklist-management/blacklistDetail.vue')
          }
        ]
      },
      // 信用评定管理
      {
        path: 'credit-rating-management',
        name: 'CreditRatingManagement',
        meta: { title: '信用评定管理', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          {
            path: 'credit-rating-template-config',
            name: 'CreditRratingTemplateConfig',
            meta: { title: '信用评定设置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/credit-rating-management/credit-rating-template-config/index.vue')
          },
          {
            path: 'credit-rating-config',
            name: 'CreditRratingConfig',
            meta: { title: '信用等级配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/credit-rating-management/credit-rating-config/index.vue')
          },
          {
            path: 'credit-rating-config-detail',
            name: 'CreditRatingConfigDetail',
            meta: {
              title: '添加信用等级',
              icon: 'star',
              standalone: {
                breadcrumb: ['CreditRatingManagement', 'CreditRratingConfig', 'CreditRatingConfigDetail'],
                activeMenu: 'CreditRratingConfig'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/credit-rating-management/credit-rating-config/creditRatingConfigDetail.vue')
          },
          {
            path: 'credit-rating-power',
            name: 'CreditRatingPower',
            meta: { title: '信用评定权限配置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/credit-rating-management/credit-rating-power/index.vue')
          },
          {
            path: 'edit-assessor/:id/:userId',
            name: 'EditAssessor',
            meta: {
              title: '编辑评定人',
              icon: 'star',
              standalone: {
                breadcrumb: ['CreditRatingManagement', 'CreditRatingPower', 'EditAssessor'],
                activeMenu: 'CreditRatingPower'
              }
            },
            component: () =>
              import('@/views/credit-rating-management/credit-rating-power/assessorDetail.vue')
          },
          {
            path: 'add-assessor/:id',
            name: 'AddAssessor',
            meta: {
              title: '添加评定人',
              icon: 'star',
              standalone: {
                breadcrumb: ['CreditRatingManagement', 'CreditRatingPower', 'AddAssessor'],
                activeMenu: 'CreditRatingPower'
              }
            },
            component: () =>
              import('@/views/credit-rating-management/credit-rating-power/assessorDetail.vue')
          },
          // 农户信用列表--农户信用结果
          {
            path: 'farmer-credit-list',
            name: 'FarmerCreditList',
            meta: { title: '农户信用结果', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/credit-rating-management/farmer-credit-list/index.vue')
          },
          // 农户信用评定详情
          {
            path: 'farmer-credit-details',
            name: 'FarmerCreditDetails',
            meta: {
              title: '信用评定详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CreditRatingManagement', 'FarmerCreditList', 'FarmerCreditDetails'],
                activeMenu: 'FarmerCreditList'
              }
            },
            component: () =>
              import('@/views/credit-rating-management/farmer-credit-list/farmerCreditDetails.vue')
          },
          // 农户信用评定
          {
            path: 'farmer-credit-judge',
            name: 'FarmerCreditJudge',
            meta: { title: '农户信用评定', icon: 'star' },
            component: () => import('@/views/credit-rating-management/farmer-credit-judge/index.vue')
          },
          // 信用评定详情
          {
            path: 'farmer-credit-judge-details',
            name: 'FarmerCreditJudgeDetails',
            meta: {
              title: '信用评定详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['CreditRatingManagement', 'FarmerCreditJudge', 'FarmerCreditJudgeDetails'],
                activeMenu: 'FarmerCreditJudge'
              }
            },
            component: () =>
              import('@/views/credit-rating-management/farmer-credit-judge/farmerCreditJudgeDetails.vue')
          },
          // 信用查看审批
          {
            path: 'credit-view-approval',
            name: 'CreditViewApproval',
            meta: { title: '信用查看审批', icon: 'star' },
            component: () => import('@/views/credit-rating-management/credit-view-approval/index.vue')
          },
          // 查看申请-审批
          {
            path: 'credit-view-details',
            name: 'CreditViewDetails',
            meta: {
              title: '查看申请-审批',
              icon: 'star',
              standalone: {
                breadcrumb: ['CreditRatingManagement', 'CreditViewApproval', 'CreditViewDetails'],
                activeMenu: 'CreditViewApproval'
              }
            },
            component: () =>
              import('@/views/credit-rating-management/credit-view-approval/creditViewDetails.vue')
          },
          // 自动评定进度
          {
            path: 'credit-rating-progress',
            name: 'CreditRatingProgress',
            meta: { title: '自动评定进度', icon: 'star' },
            component: () => import('@/views/credit-rating-management/credit-rating-progress/index.vue')
          }
        ]
      },
      // 单位管理
      {
        path: 'unit-management',
        name: 'UnitManagement',
        meta: { title: '单位管理', icon: 'star' },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          // ----------------------------审批管理---------------------
          {
            path: 'unit-approval-management',
            name: 'UnitApprovalManagement',
            meta: { title: '审批管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/unit-management/unit-approval-management/index.vue')
          },
          {
            path: 'unit-approval-setting/:ruleType',
            name: 'UnitApprovalSetting',
            meta: {
              title: '审批设置',
              icon: 'star',
              standalone: {
                breadcrumb: ['UnitManagement', 'UnitApprovalManagement', 'UnitApprovalSetting'],
                activeMenu: 'UnitApprovalManagement'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/unit-management/unit-approval-management/unitApprovalSetting.vue')
          },
          // ----------------------------单位审核---------------------------
          {
            path: 'unit-review',
            name: 'UnitReview',
            meta: { title: '单位审核', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/unit-management/unit-review/index.vue')
          },
          {
            path: 'unit-review-detail',
            name: 'UnitReviewDetail',
            meta: {
              title: '单位审核详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['UnitManagement', 'UnitReview', 'UnitReviewDetail'],
                activeMenu: 'UnitReview'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/unit-management/unit-review/unitReviewDetail.vue')
          },
          // --------------------------------单位列表----------------------------
          {
            path: 'unit-list',
            name: 'UnitList',
            meta: { title: '单位列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/unit-management/unit-list/index.vue')
          },
          {
            path: 'unit-list-detail',
            name: 'UnitListDetail',
            meta: {
              title: '单位详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['UnitManagement', 'UnitList', 'UnitListDetail'],
                activeMenu: 'UnitList'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/unit-management/unit-list/unitListDetail.vue')
          }
        ]
      },
      //项目管理
      // {
      //   path: 'project-management',
      //   name: 'ProjectManagement',
      //   meta: { title: '项目管理', icon: 'star' },
      //   redirect: '/property-right',
      //   component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/index.vue'),
      //   children: [
      //     {
      //       path: 'property-right',
      //       name: 'PropertyRight',
      //       meta: { title: '产权项目', icon: 'star' },
      //       component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/property-right/index.vue')
      //     },
      //     {
      //       path: 'property-right-details/:id',
      //       name: 'PropertyRightDetails',
      //       meta: {
      //         title: '项目审核',
      //         icon: 'star',
      //         standalone: {
      //           breadcrumb: ['ProjectManagement', 'PropertyRight', 'PropertyRightDetails'],
      //           activeMenu: 'PropertyRight'
      //         }
      //       },
      //       component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/property-right/details.vue')
      //     },
      //     {
      //       path: 'financial',
      //       name: 'Financial',
      //       meta: { title: '金融项目', icon: 'star' },
      //       component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/financial/index.vue')
      //     },
      //     {
      //       path: 'financial-details/:id',
      //       name: 'FinancialDetails',
      //       meta: {
      //         title: '项目审核',
      //         icon: 'star',
      //         standalone: {
      //           breadcrumb: ['ProjectManagement', 'PropertyRight', 'FinancialDetails'],
      //           activeMenu: 'Financial'
      //         }
      //       },
      //       component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/financial/details.vue')
      //     },
      //     {
      //       path: 'warrant-audit',
      //       name: 'WarrantAudit',
      //       meta: { title: '权证审核', icon: 'star' },
      //       component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/warrant-audit/index.vue')
      //     },
      //     {
      //       path: 'warrant-audit-detail:id',
      //       name: 'WarrantAuditDetail',
      //       meta: {
      //         title: '权证审核详情',
      //         icon: 'star',
      //         standalone: {
      //           breadcrumb: ['ProjectManagement', 'WarrantAudit', 'WarrantAuditDetail'],
      //           activeMenu: 'WarrantAudit'
      //         }
      //       },
      //       component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/project-management/warrant-audit/approvalDetail.vue')
      //     },
      //   ]
      // },
      // 行政区域信用管理
      {
        path: 'regional-credit-management',
        name: 'RegionalCreditManagement',
        meta: { title: '行政区域信用管理', icon: 'star' },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          // 村组列表
          {
            path: 'village-credit-list',
            name: 'VillageCreditList',
            meta: { title: '村组列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/village-credit-list/index.vue')
          },
          // 村组详情
          {
            path: 'village-credit-details/:id',
            name: 'VillageCreditDetails',
            meta: {
              title: '村组详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['RegionalCreditManagement', 'VillageCreditList', 'VillageCreditDetails'],
                activeMenu: 'VillageCreditList'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/village-credit-list/villageCreditDetails.vue')
          },
          // 乡镇列表
          {
            path: 'township-credit-list',
            name: 'TownshipCreditList',
            meta: { title: '乡镇列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/township-credit-list/index.vue')
          },
          // 乡镇详情
          {
            path: 'township-credit-details/:id',
            name: 'TownshipCreditDetails',
            meta: {
              title: '乡镇详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['RegionalCreditManagement', 'TownshipCreditList', 'TownshipCreditDetails'],
                activeMenu: 'TownshipCreditList'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/township-credit-list/townshipCreditDetails.vue')
          },
          // 县列表
          {
            path: 'county-credit-list',
            name: 'CountyCreditList',
            meta: { title: '县列表', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/county-credit-list/index.vue')
          },
          // 县详情
          {
            path: 'county-credit-details/:id',
            name: 'CountyCreditDetails',
            meta: {
              title: '县详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['RegionalCreditManagement', 'CountyCreditList', 'CountyCreditDetails'],
                activeMenu: 'CountyCreditList'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/county-credit-list/countyCreditDetails.vue')
          },
          // 我的审批
          {
            path: 'my-regional-approval',
            name: 'MyRegionalApproval',
            meta: { title: '我的审批', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/my-regional-approval/index.vue')
          },
          // 审批详情
          {
            path: 'regional-approval-details/:type/:approvalType',
            name: 'RegionalApprovalDetails',
            meta: {
              title: '审批详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['RegionalCreditManagement', 'MyRegionalApproval', 'RegionalApprovalDetails'],
                activeMenu: 'MyRegionalApproval'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/my-regional-approval/regionalApprovalDetails.vue')
          },
          //评定小组管理
          {
            path: 'assessment-team-management',
            name: 'AssessmentTeamManagement',
            meta: { title: '评定小组管理', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/assessment-team-management/index.vue')
          },
          // 评定小组详情
          {
            path: 'assessment-team-details/:pageType',
            name: 'AssessmentTeamDetails',
            meta: {
              title: '评定小组详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['RegionalCreditManagement', 'AssessmentTeamManagement', 'AssessmentTeamDetails'],
                activeMenu: 'AssessmentTeamManagement'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/assessment-team-management/assessmentTeamDetails.vue')
          },
          // 我的辖区
          {
            path: 'my-jurisdiction',
            name: 'MyJurisdiction',
            meta: { title: '我的辖区', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/my-jurisdiction/index.vue')
          },
          // 辖区详情
          {
            path: 'my-jurisdiction-details/:type',
            name: 'MyJurisdictionDetails',
            meta: {
              title: '辖区详情',
              icon: 'star',
              standalone: {
                breadcrumb: ['RegionalCreditManagement', 'MyJurisdiction', 'MyJurisdictionDetails'],
                activeMenu: 'MyJurisdiction'
              }
            },
            component: () =>
              import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/my-jurisdiction/myJurisdictionDetails.vue')
          },
          // 数据统计
          {
            path: 'data-statistics',
            name: 'DataStatistics',
            meta: { title: '数据统计', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/data-statistics/index.vue')
          },
          // 信用标准设置
          {
            path: 'standard-option',
            name: 'StandardOption',
            meta: { title: '信用标准设置', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/regional-credit-management/standard-option/index.vue')
          },
        ]
      },
      // 企业信用评级
      {
        path: 'enterprise-credit-rating',
        name: 'EnterpriseCreditRating',
        meta: { title: '企业信用评级', icon: 'star', alwaysShow: true },
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
          {
            path: 'enterprise-financial-rating',
            name: 'EnterpriseFinancialRating',
            meta: { title: '企业财务评级', icon: 'star' },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/enterprise-credit-rating/enterprise-financial-rating/index.vue')
          },
          {
            path: 'financial-rating/:id',
            name: 'FinancialRating',
            meta: {
              title: '评级',
              icon: 'star',
              standalone: {
                breadcrumb: ['EnterpriseCreditRating', 'EnterpriseFinancialRating', 'FinancialRating'],
                activeMenu: 'EnterpriseFinancialRating'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/enterprise-credit-rating/enterprise-financial-rating/rating.vue')
          },
          {
            path: 'rating-list/:creditCode',
            name: 'RatingList',
            meta: {
              title: '评级记录',
              icon: 'star',
              standalone: {
                breadcrumb: ['EnterpriseCreditRating', 'EnterpriseFinancialRating', 'RatingList'],
                activeMenu: 'EnterpriseFinancialRating'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/enterprise-credit-rating/enterprise-financial-rating/rating-list.vue')
          },
          {
            path: 'rating-detail/:id',
            name: 'RatingDetail',
            meta: {
              title: '企业评级记录',
              icon: 'star',
              standalone: {
                breadcrumb: ['EnterpriseCreditRating', 'EnterpriseFinancialRating', 'RatingList', 'RatingDetail'],
                activeMenu: 'EnterpriseFinancialRating'
              }
            },
            component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/enterprise-credit-rating/enterprise-financial-rating/rating-detail.vue')
          }
        ]
      },
      // 未定义页面重定向到首页
      {
        path: '*',
        meta: { title: '首页', icon: 'star' },
        component: () => import(/* webpackChunkName: 'ImportRouterPath' */'@/views/home.vue')
      }
    ]
  }
] as RouteConfig[]
