// 钱的数值转中文大写（元），最大值999999999999999.9999
export function changeNumMoneyToChinese(money: any) {
    var cnNums = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"); //汉字的数字
    var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
    var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
    var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
    var cnInteger = "整"; //整数金额时后面跟的字符
    var cnIntLast = "元"; //整型完以后的单位
    var maxNum = 999999999999999.9999; //最大处理的数字
    var IntegerNum; //金额整数部分
    var DecimalNum; //金额小数部分
    var ChineseStr = ""; //输出的中文金额字符串
    var parts; //分离金额后用的数组，预定义    
    var Symbol = "";//正负值标记
    if (money == "") {
        return "";
    }

    money = parseFloat(money);
    if (money >= maxNum) {
        // 超出最大处理数字
        return "玖佰玖拾玖兆玖仟玖佰玖拾玖亿玖仟玖佰玖拾玖万玖仟玖佰玖拾玖元玖角+";
    }
    if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
    }
    if (money < 0) {
        money = -money;
        Symbol = "负 ";
    }
    money = money.toString(); //转换为字符串
    if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = '';
    } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
    }
    if (parseInt(IntegerNum, 10) > 0) { //获取整型部分转换
        var zeroCount = 0;
        var IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
            var n = IntegerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == "0") {
                zeroCount++;
            }
            else {
                if (zeroCount > 0) {
                    ChineseStr += cnNums[0];
                }
                zeroCount = 0; //归零
                ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                ChineseStr += cnIntUnits[q];
            }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
    }
    if (DecimalNum != '') { //小数部分
        var decLen = DecimalNum.length;
        for (var i = 0; i < decLen; i++) {
            var n = DecimalNum.substr(i, 1);
            if (n != '0') {
                ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (ChineseStr == '') {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (DecimalNum == '') {
        ChineseStr += cnInteger;
    }
    ChineseStr = Symbol + ChineseStr;

    return ChineseStr;
}
// 数值每3位加逗号（不能处理带小数点）
export function toThousands(num: any) {
    let result = '', counter = 0
    num = (num || 0).toString()
    for (let i = num.length - 1; i >= 0; i--) {
        counter++;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i != 0) {
            result = ',' + result;
        }
    }
    return result
}
// 判断数值是否为null，undefined或''的特殊情况
export function numIsNull(num: any) {
    if (num === null || num === undefined || num === '') {
        return true
    }
    return false
}
// 获取数组整数和小数部分
export function getDivisionNum(num: any) {
    let returnNum: any = num.split('.')
    return [returnNum[0], returnNum[1]]
}

// 数值加千分位逗号（加空判断和简单数字转字符串）
export function toThousandsSigns(num: any) {
    let returnVal: any
    if (numIsNull(num)) {
        returnVal = ''
    } else {
        typeof num == 'number' && (num = num + '')
        let divisionArr: any = getDivisionNum(num)
        returnVal = toThousands(divisionArr[0]) + (!divisionArr[1] ? '' : '.' + divisionArr[1])
    }
    return returnVal
}

// 钱数字转中文带整余判断（元）（加空判断和简单数字转字符串）
export function numMoneyToChinese(NumMoney: any) {
    let returnVal: any
    if (numIsNull(NumMoney)) {
        returnVal = ''
    } else {
        typeof NumMoney == 'number' && (NumMoney = NumMoney + '')
        returnVal = changeNumMoneyToChinese(NumMoney)
    }
    return returnVal
}