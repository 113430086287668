var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "navbar" }, [
    _c("div", { staticClass: "bar" }, [
      _c("div", { staticClass: "left-bar" }, [
        _c("i", { staticClass: "el-icon-refresh", on: { click: _vm.refresh } }),
        _vm.isAuth === "0"
          ? _c("span", { staticClass: "verify-status unauthorized" }, [
              _vm._v("未认证")
            ])
          : _vm._e(),
        _vm.isAuth === "1"
          ? _c("span", { staticClass: "verify-status authorized" }, [
              _vm._v("已认证")
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "right-bar" },
        [
          _c(
            "el-dropdown",
            {
              staticStyle: { cursor: "pointer" },
              attrs: { trigger: "click" },
              on: { command: _vm.handleDropdownCommand }
            },
            [
              _c(
                "div",
                { staticClass: "nav-user" },
                [
                  _c("el-avatar", {
                    attrs: {
                      size: "small",
                      src:
                        "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1576825027457&di=d7ed14740d477bb8786bd1f5e04f06b0&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201704%2F25%2F20170425090349_ujk84.jpeg"
                    }
                  }),
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v(" " + _vm._s(_vm.userName) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "logout" } }, [
                    _vm._v("退出")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("img", {
            staticClass: "question-img",
            attrs: { src: _vm.questionSrc },
            on: { click: _vm.help }
          }),
          _c("span", { staticClass: "help", on: { click: _vm.help } }, [
            _vm._v("帮助")
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }