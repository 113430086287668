// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#001529",
	"menuText": "#fff",
	"menuActiveText": "#0062ff",
	"submenuActiveText": "#fff",
	"submenuBg": "#000c17",
	"submenuHover": "#0486fe",
	"submenuTextHover": "#fff",
	"logoBg": "#002140"
};
module.exports = exports;
