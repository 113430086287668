// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.el-button--primary {\n  background-color: #0062ff;\n}\n.el-button--text,\n.el-button--text:focus,\n.el-button--text:active,\n.el-button--text:hover {\n  color: #0062ff;\n}\n.el-radio__input.is-checked .el-radio__inner,\n.el-checkbox__input.is-checked .el-checkbox__inner {\n  background-color: #0062ff;\n  border-color: #0062ff;\n}\n.commonText {\n  color: #0062ff !important;\n}\n", ""]);
// Exports
exports.locals = {
	"menuBg": "#001529",
	"menuText": "#fff",
	"menuActiveText": "#0062ff"
};
module.exports = exports;
