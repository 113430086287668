import store from '@/store'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators'

export interface ISettingsState {
  hideSidebar: boolean
}

@Module({ dynamic: true, store, name: 'Settings' })
export class Settings extends VuexModule implements ISettingsState {
  public hideSidebar: boolean = false

  @Mutation
  TOGGLE_SIDEBAR() {
    this.hideSidebar = !this.hideSidebar
  }

  @Action
  toggleSidebarAction() {
    this.TOGGLE_SIDEBAR()
  }
}

export const SettingsModule = getModule(Settings)
