
import { Component, Vue } from 'vue-property-decorator'
import { jumpLogin } from '@/api/login'
import { localStore } from './utils/data-management'
import { getValueFromUrlQuery } from '@/utils/url'
import { getSearch } from '@/utils/getSearch'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({})
export default class App extends Vue {
  private loadingTxt: string = loadingText
  private options: boolean = false
  private get userInfoLoading(): boolean {
    return this.$store.state.loadingUserInfo
  }

  private created() {
    let searchValue = getSearch()
    // let searchValue = '?ticket=TLsUjM4OCFaPNE1RS0ed'
    if (searchValue.indexOf('ticket=') === 1) {
      let data = getValueFromUrlQuery(['ticket'])
      // 发版切换修改
      // let dataString = data.ticket.substring(0, data.ticket.indexOf('#'))
      let dataString = data.ticket
      if (localStore.get('ticket') !== dataString) {
        localStore.clear()

        this.getToken(dataString)
        this.$router.push({ name: 'Home', params: { bID: 'P002' } })
      }
    }
  }
  private getToken(dataString: string) {
    this.$loading({
      fullscreen: true,
      text: this.loadingTxt
    })
    let systemType: any = process.env.VUE_APP_SYSTEM_TYPE,
      datas: any = {
        ticket: dataString,
        client: 'admin',
        system: systemType == 'rural' ? 'ruralBg' : 'zctBg',
        app: 'pc'
      }
    jumpLogin(datas)
      .then((res) => {
        this.$loading({
          fullscreen: true
        }).close()
        if (res.data.data) {
          this.$router.push({ name: 'Home' })
          localStore.set('zctToken', res.data.data)
          localStore.set('ticket', dataString)
          this.$store.commit('CHANGE_ISLOGIN', res.data.data)
        }
      })
      .catch((res) => {
        this.open(res.data.message, '提示', '1', '重新进入本系统', false, '')
        this.$store.commit('CHANGE_ROUTEARR', '0')
        localStore.remove('zctToken')
        // this.$loading({ fullscreen: true}).close()
      })
      .finally(() => {
        this.$loading({ fullscreen: true }).close()
      })
  }

  // 弹框处理
  private open(message: string, title: string, flag: string, confirmButtonText?: string, showCancelButton?: boolean, cancelButtonText?: string) {
    this.$confirm(message, title, {
      distinguishCancelAndClose: false,
      type: 'warning',
      showClose: false,
      confirmButtonText: confirmButtonText || '去认证',
      cancelButtonText: cancelButtonText || '退出',
      showCancelButton: showCancelButton || false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      center: true,
      lockScroll: true
    })
      .then((res) => {
        if (res === 'confirm') {
          // this.$router.push({ name: 'Home', params: { flag: '1' } })
          this.$store.dispatch('logout')
        }
        // if (flag === '1') {
        //   this.$store.dispatch('logout')
        // } else {
        //   // ...
        // }
      })
      .catch((res) => {
        if (res === 'cancel') {
          this.$store.dispatch('logout')
        }
      })
  }
}
