var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.breadcrumbs.length == 1 && _vm.breadcrumbs[0].meta.title == "首页"
        ? _c("div", { staticStyle: { width: "100%", height: "50px" } })
        : _c(
            "el-breadcrumb",
            { staticClass: "app-breadcrumb", attrs: { separator: "/" } },
            [
              _c(
                "transition-group",
                { attrs: { name: "breadcrumb" } },
                _vm._l(_vm.breadcrumbs, function(item, index) {
                  return _c("el-breadcrumb-item", { key: item.path }, [
                    index === _vm.breadcrumbs.length - 1
                      ? _c("span", [_vm._v(_vm._s(item.meta.title))])
                      : _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.handleLink(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.meta.title))]
                        )
                  ])
                }),
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }