var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "layout", style: _vm.themeStyle },
    [
      _c("Sidebar", {
        class: ["left-side", { hideSidebar: _vm.isCollapse }],
        attrs: { themeStyle: _vm.themeStyle }
      }),
      _c("div", { staticClass: "right-side" }, [
        _c("header", [_c("Navbar"), _c("BreadCrumb")], 1),
        _c("section", [_c("AppMain")], 1)
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }