
import { Component, Vue, Watch } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

@Component({
  name: 'AppMain'
})
export default class extends Vue {
  get key() {
    // 2个路由 公用组件时 会用到
    return this.$route.fullPath
  }

  @Watch('$route', { deep: true })
  hanlder() {
    ;(this.$el as HTMLDivElement).scrollTop = 0
  }
}
